export const FETCH_STAFF_LIST_REQ = 'FETCH_STAFF_LIST_REQ';
export const FETCH_STAFF_LIST_SUCCESS = 'FETCH_STAFF_LIST_SUCCESS';
export const FETCH_STAFF_LIST_FAILURE = 'FETCH_STAFF_LIST_FAILURE';

export const fetchStaffListReq = data =>({
    type:FETCH_STAFF_LIST_FAILURE,
    data,
})

export const fetchStaffListSuccess = data =>({
    type:FETCH_STAFF_LIST_SUCCESS,
    data,
})
export const fetchStaffListFailure = error =>({
    type:FETCH_STAFF_LIST_FAILURE,
    error,
})