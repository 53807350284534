import React from 'react';


class ProgressBarWithNumber extends React.Component {

  render() {
    return (
      <div style={{ width: "165px" }} className="rsvp">
        {!!this.props.percentage &&
          (
            <>
              <ProgressBarView percentage={this.props.percentage} />
              <span className="filledText">{this.props.percentage.toFixed(2)}% Filled</span>
            </>
          )
        }
      </div>
    )
  }
}

const ProgressBarView = (props) => {
  return (
    <div className="customProgressbar">
      <Filler percentage={props.percentage} />
    </div>
  )
}

const Filler = (props) => {
  return <div className="filler" style={{ width: `${props.percentage}%` }} />
}

export default ProgressBarWithNumber;