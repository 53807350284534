import React, { Component } from 'react'
import moment from "moment";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Icon, Button, Typography } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        display: "flex",
        minHeight: "300px"
    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto"
    },
    cover: {
        height: 400,
        margin: "-50px 0px",
    },
    coverContent: {
        padding: 25
    },
    rsvpIcon: {
        padding: "20px 0px 0px 15px",
        height: 70,
    },
    rsvpDateTime: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        flexDirection: "row-reverse",
        textAlign: "right"
    },
    rsvpDateTimeChild: {
        flex: "0 0 calc(70% - 10px)"
    },
    rsvpDateTimeChildimg: {
        flex: "0 0 calc(30% - 10px)"
    },
    dateTimeCard: {
        padding: 20,
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
    dateTitme: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#05b9a4",
    },
    slotTime: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#000",
        border: "1px solid rgba(143, 143, 150, 0.38)",
        borderRadius: "50px",
        padding: "0px 10px",
        textAlign: "center"
    },
    breakTag: {
        textAlign: "center",
        marginTop: "10px"
    },
    control: {
        margin: "25px 0px"
    },
    addTime: {
        border: "1px solid #05b9a4",
        backgroundColor: "#05b9a4",
        color: "#fff",
        borderRadius: "50px",
        position: "absolute",
        left: "49%",
        "&:hover": {
            backgroundColor: "#05b9a4",
        },
        dataSlotSection: {
            marginBottom: 15,
        }
    }
});

export class RenderDay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenDatePicker: false,
            selectedDate: "",
            index: "",
        }
    }

    componentDidMount() {
        const { range, fields } = this.props
        const days = fields.name.split('.')[1]
        this.getDay(range, days)
    }

    componentWillReceiveProps(newProps) {
        const { range, fields } = newProps;

        if (range !== this.props.range) {
            const days = fields.name.split('.')[1]
            this.getDay(range, days)
        }
    }

    getDay(range, day) {
        const { from, to } = range
        const momentStartDate = moment(from);
        const momentEndDate = moment(to);
        const dates = [];
        this.props.fields.removeAll();
        for (let m = moment(momentStartDate); m.diff(momentEndDate, 'day') <= 0; m.add(1, 'month')) {
            if (m.isValid()) {
                dates.push(m.toDate());
                this.props.fields.push(m.toDate())
            }
        }
    }

    onChangeHandle = (selectedDate) => {
        this.setState({ selectedDate })
    }

    openDatePicker = (index, date) => (e) => {
        this.setState({
            index,
            isOpenDatePicker: true,
            selectedDate: moment(date),
        })
    }

    closeDatePicker = (e) => {
        this.setState({
            isOpenDatePicker: false,
            selectedDate: ''
        })
    }

    onAcceptHandle = (day) => {
        const date = moment(day).toDate();
        setTimeout(() => {
            this.props.fields.remove(this.state.index)
        }, 0);
        setTimeout(() => {
            this.props.fields.insert(this.state.index, date)
        }, 0);
    }



    render() {
        const { classes, fields } = this.props;
        const { isOpenDatePicker, selectedDate } = this.state;
        return (
            <div className={classes.root}  >
                <Grid container>
                    <Grid item xs={12} className={classes.control}>
                        <Card className={classes.dateTimeCard}>
                            {fields.map((date, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={3}>
                                        <Moment format="MMM D, YYYY">
                                            {fields.get(index)}
                                        </Moment>
                                        <Typography style={{ cursor: "pointer" }} onClick={this.openDatePicker(index, fields.get(index))} variant="caption">
                                            Change Date
                                        </Typography>


                                    </Grid>
                                    <Grid item xs={3} >
                                        <Grid container>
                                            <Grid item xs={10} >
                                                <Moment format="dddd">
                                                    {fields.get(index)}
                                                </Moment>
                                            </Grid>
                                            <Grid item xs={2} className="deleteIcon">
                                                <Button onClick={() => fields.remove(index)}>
                                                    <Icon>delete</Icon>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Card>
                    </Grid>
                </Grid>
                <DatePicker
                    style={{ display: "none" }}
                    variant="dialog"
                    open={isOpenDatePicker}
                    onClose={this.closeDatePicker}
                    label="Open me from button"
                    format="d MMM yyyy"
                    value={selectedDate}
                    onAccept={this.onAcceptHandle}
                    onChange={this.onChangeHandle}
                />
            </div>
        )
    }
}

export default withStyles(styles)(RenderDay)
