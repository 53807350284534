
import {
    GET_PHOTOS
} from "../actions/photos";


const INITIAL_STATE = {
    classid : 1, photo_list : [], source_list : []
}

const photos = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PHOTOS:
            return {
                ...state,
                photo_list : action.data
            }

        default:
            return state
    }
}


export default photos;