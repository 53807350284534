import React, { Component } from 'react';
import { Form, Input, Col, Row } from 'antd';

import {
    withStyles, Button, Dialog, Slide,
    DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import groupService from '../service/group';
import { connect } from 'react-redux';

const { Item: FormItem } = Form;

const styles = theme => ({
    paper: {
        width: '80%',
        maxHeight: 435,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class GroupManageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            value: [],
            optionLoading: false
        };
    }


    onFormSubmit = async () => {
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (err) {
                console.log(err);
                return;
            }
            this.props.handleClose();
            if (Object.keys(this.props.currentGroup).length > 0) {
                const body = {
                    ...data,
                    groupname: data.groupname.split(' ').join('_')
                }
                groupService.updateGroup(body).then(res => {
                    setTimeout(() => {
                        this.props.fetchGroup();
                    }, 100);
                });
            } else {
                const body = {
                    ...data,
                    groupname: data.groupname.split(' ').join('_')
                }
                groupService.addGroup(body).then(res => {
                    
                    setTimeout(() => {
                        this.props.fetchGroup();
                    }, 100);
                });
            }
        });
    }

    render() {
        const {
            classes, handleClose, open,
            form: { getFieldDecorator },
            currentGroup: { groupname, desc }
        } = this.props;

        const isUpdate = Object.keys(this.props.currentGroup).length === 0 ? false : true;
        return (
            <div>
                <Dialog
                    disableBackdropClick
                    maxWidth="xs"
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <DialogTitle >Group</DialogTitle>
                    <DialogContent>
                        <Form>
                            <Row gutter={40}>
                                <Col>
                                    <FormItem label="Group Name">
                                        {getFieldDecorator('groupname', {
                                            rules: [{ required: true, message: 'Please enter Group Name!' }],
                                            initialValue: groupname
                                        })(
                                            <Input width='100%' placeholder="Group Name" readOnly={isUpdate} />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={40}>
                                <Col>
                                    <FormItem label="Description">
                                        {getFieldDecorator('description', {
                                            rules: [{ required: true, message: 'Please enter description!' }],
                                            initialValue: desc
                                        })(
                                            <Input placeholder="Description" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            cancel
                        </Button>
                        <Button onClick={this.onFormSubmit} color="primary">
                            save
                        </Button>
                    </DialogActions>

                </Dialog >
            </div >
        );
    }
}

const StaffManageWithStyles = withStyles(styles)(GroupManageComponent)

const StaffManageForm = Form.create()(StaffManageWithStyles);

export default connect()(StaffManageForm);

