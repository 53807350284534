import {
    // ADD_EVENT,
    // UPDATE_EVENT,
    SET_INITIAL_VALUE,
    GET_EVENT,
    RESET_FORM_INITAL_VALUE,
    SET_STEPPER_ACTIVE_STEP,
    GET_EVENT_BY_ID,
    FILTER_RSVP_RECORD,
    GET_EVENT_RESPONSE,
    SEARCH_RSVP_RECORD,
    FETCH_RSVP_EVENT_BY_USER,
    FETCH_PARENT_TEACHER_EVENT_BY_USER,
    SET_CURRENT_EVENT_ID,
    FETCH_FILTER_BY_USER
} from "../actions/event";
import { REQUEST, SUCCESS, FAILURE } from "../actions/common";

const INITIAL_STATE = {
    isLoading: false,
    error: {},
    stepper: { activeStep: 1 },
    formInitialValue: {},
    rsvpEventResponse: {},
    rsvpFilterValue: 'Sent',
    searchText: '',
    eventList: [],
    eventById: [],
    userRSVPEventResponse: [],
    parentTeacherEventResponse: [],
    currentEventId: null,
    filterRSVPUserResponse: [],
    count: null
}

const event = (state = INITIAL_STATE, { payload, error, query, ...action }) => {
    switch (action.type) {

        case SET_STEPPER_ACTIVE_STEP:
            const stepper = { ...state.stepper, activeStep: payload };
            return { ...state, stepper };

        case FILTER_RSVP_RECORD:
            return {
                ...state,
                rsvpFilterValue: payload
            }
        case SEARCH_RSVP_RECORD:
            return {
                ...state,
                searchText: payload
            }

        case RESET_FORM_INITAL_VALUE:
            return {
                ...state,
                stepper: INITIAL_STATE.stepper,
                formInitialValue: payload
            };

        case SET_INITIAL_VALUE:
            return {
                ...state,
                formInitialValue: payload
            };

        case SET_CURRENT_EVENT_ID:
            return {
                ...state,
                currentEventId: payload
            }


        case GET_EVENT[REQUEST]:
        case GET_EVENT_BY_ID[REQUEST]:
        case GET_EVENT_RESPONSE[REQUEST]:
        case FETCH_RSVP_EVENT_BY_USER[REQUEST]:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_PARENT_TEACHER_EVENT_BY_USER[REQUEST]:
            return {
                ...state,
                isLoading: true
            }

        case GET_EVENT[FAILURE]:
        case GET_EVENT_BY_ID[FAILURE]:
        case GET_EVENT_RESPONSE[FAILURE]:
        case FETCH_RSVP_EVENT_BY_USER[FAILURE]:
        case FETCH_FILTER_BY_USER[FAILURE]:
            return {
                ...state,
                isLoading: false,
                error: error
            }
        case FETCH_PARENT_TEACHER_EVENT_BY_USER[FAILURE]:
            return {
                ...state,
                isLoading: false,
                error: error
            }

        case GET_EVENT[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                eventList: payload,
            };

        case GET_EVENT_BY_ID[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                eventById: payload
            };

        case GET_EVENT_RESPONSE[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                rsvpEventResponse: payload
            }

        case FETCH_RSVP_EVENT_BY_USER[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                userRSVPEventResponse: payload
            }

        case FETCH_FILTER_BY_USER[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                count: payload.count,
                filterRSVPUserResponse: payload.result
            }

        case FETCH_PARENT_TEACHER_EVENT_BY_USER[SUCCESS]:
            return {
                ...state,
                isLoading: false,
                parentTeacherEventResponse: payload
            }

        default:
            return state
    }
}


export default event;

/* case ADD_EVENT[SUCCESS]:
case UPDATE_EVENT[SUCCESS]:
        return {
            ...state,
            formInitialValue: INITIAL_STATE.formInitialValue
        }; */