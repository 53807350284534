import React from 'react';

class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: 1 };
    this._handleUpdate = this._handleUpdate.bind(this);
    this._reset = this._reset.bind(this);
    this.props.onChange(1)
  }

  _handleUpdate(e) {
    if (e.target.validity.valid && e.target.value >= 0) {
      this.setState({ inputValue: e.target.value });
      this.props.onChange(Number(e.target.value))
    }
  }

  _reset() {
    this.setState({ inputValue: "" });
  }

  render() {
    return (
      <>
        <input
          type="number"
          value={this.state.inputValue}
          onChange={this._handleUpdate}
          step="any"
          className="roundTextBox"
        />
      </>
    )
  }
}

export default NumericInput;
