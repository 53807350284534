import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';
import moment from "moment"

function RenderDatePicker(props) {
    const {
        label,
        className,
        meta: { error },
        input,
        ...custom
    } = props

    const initialValue = input.value || new Date();
    const [selectedDate, setSelectedDate] = useState(moment(initialValue));

    function handleDateChange(value) {
        setSelectedDate(value);
        input.onChange(moment(value).toDate())
    }


    return (
        <>
            <DatePicker
                margin="none"
                label={label}
                className={className}
                inputVariant='outlined'
                aria-describedby="component-error-text"
                value={selectedDate}
                onChange={handleDateChange}
                {...custom}
                // {...input}
                // error={error}
            />
            <FormHelperText id="component-error-text" className="errorText">{error}</FormHelperText>
        </>
    );
}
export default RenderDatePicker;