import moment from 'moment';
import {
    SET_CLASS_ID,
    SET_DATE_RANGE,
    SET_SEARCH_TEXT,
    SET_CLASS_LIST,
    FETCH_ATTENDANCE_DATA
} from "../actions/attendance";


const INITIAL_STATE = {
    fromdate: moment().format('YYYY/MM/DD'),
    todate: moment().format('YYYY/MM/DD'),
    classList: [],
    classid: -1,
    search: '',
    tableRow: [],
    data: [],
    error: null,
    isLoading: false,
}

const attendance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DATE_RANGE:
            return {
                ...state,
                fromdate: action.data.fromdate,
                todate: action.data.todate,
            }
        case SET_CLASS_ID:
            return {
                ...state,
                classid: action.data,

            }
        case SET_SEARCH_TEXT:
            return {
                ...state,
                search: action.data,
                tableRow: filterRow(state.data, action.data)
            }
        case SET_CLASS_LIST:
            return {
                ...state,
                classList: action.data,
                // classid: action.data[0].groupid
            }
        case FETCH_ATTENDANCE_DATA:
            return {
                ...state,
                tableRow: filterRow(action.data,state.search),
                data: action.data,
            }
        default:
            return state
    }
}

function filterRow(data, text) {
    const searchString = text.toLowerCase();
    if (searchString !== '') {
        const newTableRow = data.filter(row => (
            row.firstname.toLowerCase().includes(searchString) ||
            row.lastname.toLowerCase().includes(searchString)
        ))
        return newTableRow;
    } else {
        return data;
    }
}

export default attendance;