export const FETCH_PARENT_LIST_REQ = 'FETCH_PARENT_LIST_REQ';
export const FETCH_PARENT_LIST_SUCCESS = 'FETCH_PARENT_LIST_SUCCESS';
export const FETCH_PARENT_LIST_FAILURE = 'FETCH_PARENT_LIST_FAILURE';

export const fetchParentListReq = data =>({
    type:FETCH_PARENT_LIST_FAILURE,
    data,
})

export const fetchParentListSuccess = data =>({
    type:FETCH_PARENT_LIST_SUCCESS,
    data,
})
export const fetchParentListFailure = error =>({
    type:FETCH_PARENT_LIST_FAILURE,
    error,
})