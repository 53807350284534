import React, { Component } from 'react';
import { Select, Spin, Form, Input, Col, Row, message } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    withStyles, Button, 
    AppBar, Typography, Avatar, 
    Card, CardContent, Tab, Tabs,
    Grid, List, ListItem, ListItemText
} from '@material-ui/core';
import parentService from '../service/parent';

const { Option } = Select;
const { Item: FormItem } = Form;

const headerHeight = 120;

const styles = theme => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    flex: {
        flex: 1,
    },
    flexGrow: {
        flexGrow: 1
    },

    sidebarHeader: {
        height: headerHeight,
        minHeight: headerHeight,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    },
    sidebarHeaderInnerSidebar: {
        backgroundColor: 'transparent',
        color: 'inherit',
        height: 'auto',
        minHeight: 'auto'
    },
    header: {
        height: headerHeight,
        minHeight: headerHeight,
        display: 'flex',
        backgroundImage: 'url("/assets/header-bg.png")',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        backgroundSize: 'cover'
    },
    layoutHeader: {
        height: 260,
        minHeight: 260,
        background: "url('/assets/dark-material-bg.jpg') no-repeat",
        backgroundSize: 'cover',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            height: 180,
            minHeight: 180
        }
    },
    listContainer: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },



});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}


class ParentManageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            value: [],
            optionLoading: false,
            selectedTab: 0,
            currentParent: {},
            students: null,
            spouse: null
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            const { username } = this.props.location.state;
            this.getUserInfo(username);
            this.findChildAndSpouse(username);
        }
    }

    findChildAndSpouse = async (username) => {
        try {
            const body = { username }
            const res = await parentService.findUserChildAndSpouse(body)
            if (res.data) {
                const { students, spouse } = res.data;
                this.setState({ students, spouse });
            }
            if (res.errorMessage) {
                message.error('something not right!')
            }

        } catch (error) {
            message.error('something wrong')
        }
    }

    getUserInfo = async (username) => {
        try {
            const body = { email : username }
            const res = await parentService.getUserDetailByID(body);
            if (res.user) {
                const groups = res.user.groups;
                const currentParent = { ...res.user, groups };
                this.setState({ currentParent });
            }
            if (res.errorMessage) {
                message.error('something not right!')
            }

        } catch (error) {
            message.error('something not right!')
        }

    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onFormSubmit = async () => {
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (err && (!err['password'] || !err['confirmpassword'])) {
                return;
            }

            if (Object.keys(this.state.currentParent).length > 0) {
                const body = {
                    role: 'parent',
                    username: data.email,
                    ...data,
                }
                parentService.editParent(body).then(res => {
                    this.props.history.goBack()
                })

            } else {
                const { confirmpassword, ...rest } = data;
                if (confirmpassword !== data.password) {
                    message.error('Passwords don\'t match');
                    return;
                }
                const body = {
                    role: 'parent',
                    SetasFinalPassword: 'Y',
                    username: data.email,
                    ...rest
                };

                if (data.email) {
                    parentService.addParentDB(body).then(res => {

                        //parentService.addParentDB(body)
                        if (res.status === "error"){
                            message.error('Error Creating User !')
                        }else{
                            this.props.history.goBack()
                        }
                    });
                }

            }

        });

    }

    render() {
        const {
            classes,
            form: { getFieldDecorator },
            currentUser: { userInfo: { Groups: allgroups = [] } },
        } = this.props;
        const {
            optionLoading,
            selectedTab,
            students,
            spouse,
            currentParent,
        } = this.state;

        const { email = '', given_name = '', family_name = '', phone_number = '', groups = [] } = currentParent;
        const isUpdate = Object.keys(this.state.currentParent).length === 0 ? false : true;

        return (
            <div className={classes.root}>

                <div className={classNames(classes.header, classes.layoutHeader)}>
                    <div className="p-24 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end">
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-row md:items-center md:justify-start">
                            {given_name && <Avatar className="w-80 h-80 mr-8 text-40">{given_name[0].toUpperCase()}</Avatar>}
                            {!given_name &&
                                <Typography className="md:ml-24"  color="inherit">
                                    Add New Parent
                                        </Typography>
                            }
                            <Typography className="md:ml-24"  color="inherit">
                                {given_name && family_name && `${given_name} ${family_name}`}
                            </Typography>
                        </div>

                        <div className="flex items-center justify-end">
                            <Button className=" mr-8 normal-case" variant="contained" color="primary" aria-label="cancel" onClick={this.onFormSubmit} >save</Button>
                            <Button className="normal-case" variant="contained" color="secondary" aria-label="save" onClick={() => { this.props.history.goBack() }} >cancel</Button>
                        </div>
                    </div>
                </div>
                <AppBar position="static" color="default">
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >

                        <Tab label="Profile" />
                    </Tabs>
                </AppBar>
                {selectedTab === 0 &&
                    <TabContainer>
                        <div className={classes.flexGrow}>
                            <Grid container spacing={5}>
                                <Grid item xs={8}>
                                    <Card >
                                        <CardContent>
                                            <Form>
                                                <Row gutter={40}>
                                                    <Col span={12}>
                                                        <FormItem label="First Name">
                                                            {getFieldDecorator('firstname', {
                                                                rules: [{ required: true, message: 'Please enter First Name!' }],
                                                                initialValue: given_name
                                                            })(
                                                                <Input width='100%' placeholder="First Name" />
                                                            )}
                                                        </FormItem>
                                                    </Col>

                                                    <Col span={12}>
                                                        <FormItem label="Last Name">
                                                            {getFieldDecorator('lastname', {
                                                                rules: [{ required: true, message: 'Please enter Last Name!' }],
                                                                initialValue: family_name
                                                            })(
                                                                <Input placeholder="Last Name" />
                                                            )}
                                                        </FormItem>

                                                    </Col>

                                                {/*{!isUpdate &&*/}
                                                {/*<Col span={12}>*/}
                                                {/*    <FormItem label="Password">*/}
                                                {/*        {getFieldDecorator('password', {*/}
                                                {/*            rules: [{ required: !isUpdate, message: 'Please enter password' },*/}
                                                {/*                { min: 8, message: 'Password should be atleast 8 characters long' }],*/}
                                                {/*        })(*/}
                                                {/*            <Input type="password" placeholder="password" />*/}
                                                {/*        )}*/}
                                                {/*    </FormItem>*/}
                                                {/*</Col>*/}
                                                {/*}*/}

                                                {/*{!isUpdate &&*/}
                                                {/*<Col span={12}>*/}
                                                {/*    <FormItem label="Confirm Password">*/}
                                                {/*        {getFieldDecorator('confirmpassword', {*/}
                                                {/*            rules: [{ required: !isUpdate, message: 'Please re-enter password' }],*/}
                                                {/*        })(*/}
                                                {/*            <Input type="password" placeholder="Confirm Password" />*/}
                                                {/*        )}*/}
                                                {/*    </FormItem>*/}
                                                {/*</Col>*/}

                                                {/*}*/}

                                                    <Col span={12}>
                                                        <FormItem label="Email / UserName">
                                                            {getFieldDecorator('email', {
                                                                rules: [{ required: true, message: 'Please enter parent email' }],
                                                                initialValue: email
                                                            })(
                                                                <Input placeholder="Email" width='100%' readOnly={isUpdate} />
                                                            )}
                                                        </FormItem>
                                                    </Col>

                                                    <Col span={12}>
                                                        <FormItem label="Phone">
                                                            {getFieldDecorator('phone', {
                                                                rules: [
                                                                    { required: true, message: 'Please enter Phone Number' },
                                                                    { pattern: new RegExp("^(([+]{1}[1]{1})[0-9]{10})$"), message: 'Phone Number can be must be 10 digit and start with +1' }
                                                                ],
                                                                initialValue: phone_number
                                                            })(
                                                                <Input placeholder="phone" />
                                                            )}
                                                        </FormItem>
                                                    </Col>

                                                    <Col span={12}>
                                                        <FormItem label="groups">
                                                            {getFieldDecorator('groups', {
                                                                rules: [{ required: true, message: 'Please select groups!' }],
                                                                initialValue: groups
                                                            })(
                                                                <Select
                                                                    mode="multiple"
                                                                    style={{ width: '100%' }}
                                                                    notFoundContent={optionLoading ? <Spin size="small" /> : null}
                                                                    placeholder="Please select groups"
                                                                >
                                                                    {Object.keys(allgroups).map((key, index) => <Option key={key} value={allgroups[key].groupid}>{allgroups[key].GroupName}</Option>)}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    {(students || spouse) &&
                                        <Card >
                                            <CardContent>
                                                {spouse &&
                                                    <div>
                                                        <Typography variant="subheading" gutterBottom>
                                                            Spouse
                                                                </Typography>
                                                        <List className={classes.listContainer} >
                                                            {spouse.map(_spouse => (
                                                                <ListItem key={_spouse.parentid}>
                                                                    <Avatar alt="avtar">{_spouse.parentid[0]}</Avatar>
                                                                    <ListItemText primary={_spouse.parentid} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </div>
                                                }
                                                {students &&
                                                    <div>
                                                        <Typography variant="subheading" gutterBottom>
                                                            Students
                                                                </Typography>

                                                        <List className={classes.listContainer} >
                                                            {students.map(student => (
                                                                <ListItem key={student.childid}>
                                                                    <Avatar alt="avtar">{student.firstname[0]}</Avatar>
                                                                    <ListItemText primary={`${student.firstname} ${student.lastname}`} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </div>
                                                }

                                            </CardContent>
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </TabContainer>
                }

            </div >
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

const ParentManageWithStyles = connect(mapStateToProps)(withStyles(styles)(ParentManageComponent))
const ParentManageForm = Form.create()(ParentManageWithStyles);

export default ParentManageForm;

