import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeDialogBox } from '../redux/actions/component';
import FilterDialog from '../component/FeedCard/FilterDialog';
import { MangePostSelector } from '../redux/selector';
import { applyPostFilter } from '../redux/actions/feed-post';
import { handleFilterRadioChange, handleFilterSelectGroup, handleFilterSelectChild } from '../redux/actions/filter-post';

const mapStateToProps = state => {
  const GroupOptions = MangePostSelector.convertGroupOptions(state)
  return {
    GroupOptions,
    currentUser: state.currentUser.userInfo,
    dialogProps: state.component.dialog,
    filterpost: state.filterpost,

  }
}

const mapDispatchToProps = dispatch => ({
  closeDialogAction: bindActionCreators(closeDialogBox, dispatch),
  applyPostFilterAction: bindActionCreators(applyPostFilter, dispatch),
  handleFilterRadioChangeAction: bindActionCreators(handleFilterRadioChange, dispatch),
  handleFilterSelectGroupAction: bindActionCreators(handleFilterSelectGroup, dispatch),
  handleFilterSelectChildAction: bindActionCreators(handleFilterSelectChild, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);