export const required = value => {
  return value ? undefined : "Required";
  // (value || typeof value === 'number' ? undefined : 'Required')
};
export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const pinCode = value =>
  value && !/^(0|[1-9][0-9]{5})$/i.test(value)
    ? "Invalid pin number, must be 6 digits"
    : undefined;
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;
export const alpha = value =>
  value && /[^a-zA-Z ]/i.test(value) ? "Only alphabetic characters" : undefined;
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
