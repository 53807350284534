import BriefCase from '../component/BriefCase/BriefCase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialize } from "redux-form";

import { BriefCaseSelector } from '../redux/selector/briefCaseSelector';
import { fetchDraftPost, fetchSchedulePost, fetchReviewPost } from '../redux/actions/breifcase';
import { deletePost, editPost } from '../redux/actions/feed-post';
import { openDialogBox } from '../redux/actions/component';
import {fetchUserCatch} from "../redux/actions/cache";

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    cache: state.cache,
    draft: {
        feeds: BriefCaseSelector.getDraftPostsWithUser(state),
        query: state.briefcase.draft.query,
    },
    schedule: {
        feeds: BriefCaseSelector.getSchedulePostsWithUser(state),
        query: state.briefcase.schedule.query,
    },
    review: {
        feeds: BriefCaseSelector.getReviewPostsWithUser(state),
        query: state.briefcase.review.query,
    },
    userGroups: state.currentUser.userInfo.Groups ?
        Object.values(state.currentUser.userInfo.Groups) : []
});

const mapDispatchToProps = dispatch => ({
    fetchDraftPostAction: bindActionCreators(fetchDraftPost, dispatch),
    fetchReviewPostAction: bindActionCreators(fetchReviewPost, dispatch),
    fetchSchedulePostAction: bindActionCreators(fetchSchedulePost, dispatch),
    deletePostAction: bindActionCreators(deletePost, dispatch),
    editPostAction: bindActionCreators(editPost, dispatch),
    getUserCatch: bindActionCreators(fetchUserCatch, dispatch),
    openDialogAction: bindActionCreators(openDialogBox, dispatch),
    formInitializeAction: bindActionCreators(initialize, dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(BriefCase)

