import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {
    reduxForm, Form, FieldArray,
    change, formValueSelector, SubmissionError
} from 'redux-form';



import validate from "../../../common/reduxForm/validation/slots";
import { setInitialValue } from '../../../../redux/actions/event';
import DatesCard from "./DatesCard";
import AddSpots from "./AddSpots";
import moment from "moment";
import { showToastMessage } from "../../../../redux/actions/component";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    dateTimeCard: {
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
    datePickerSection: {
        margin: "0 auto"
    },
    card: {
        display: "flex",
        minHeight: "300px"
    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto"
    },
    cover: {
        height: 400,
        margin: "-50px 0px",
    },
    coverContent: {
        padding: 25
    },
    rsvpIcon: {
        padding: "20px 0px 0px 15px",
        height: 70,
    },
    rsvpDateTime: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        flexDirection: "row-reverse",
        textAlign: "right"
    },
    rsvpDateTimeChild: {
        flex: "0 0 calc(70% - 10px)"
    },
    rsvpDateTimeChildimg: {
        flex: "0 0 calc(30% - 10px)"
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    },
    slotReminderTitle: {
        padding: "35px",
        textAlign: "left",
        color: "#05b9a4",
        fontWeight: "bold"
    },
    slotReminderTitleYear: {
        fontWeight: "normal",
        paddingLeft: "10px"
    },
    slot: {
        color: "#3c3989",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    slotContainer: {
        backgroundColor: "#f8f8f8"
    },
    itemDetails: {
        color: "#000000",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    additional: {
        color: "#797979",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "17px",
        textAlign: "right",
        marginTop: "-50px"
    },
    link: {
        cursor: "pointer",
        padding: "0 20px"
    }
});

function getLabel(date, slots) {
    return `${moment(date.event_date).format('ddd')}, ${moment(date.event_date).format('DD/MM/YYYY')} @ ${moment(slots.start_time).format('hh:mmA')}-${moment(slots.end_time).format('hh:mmA')}`
}

class Slots extends Component {

    handlePreviousStep = () => {
        const {
            setStepperActiveStep,
            previousStep
        } = this.props;
        setStepperActiveStep(previousStep);
    };

    handleNextStep = async () => {
        const {
            generalDetailsubmitForm,
            setStepperActiveStep,
            nextStep
        } = this.props;
        await generalDetailsubmitForm();
        setStepperActiveStep(nextStep);
    }

    onFormSubmit = data => {
        const error = validate(data);
        if (error.volunteer) {
            this.props.dispatch(showToastMessage({ message: error.volunteer._error, type: 'error' }))
            throw new SubmissionError({ _error: error.volunteer._error })
        } else {
            this.props.setInitialValue(data);
            this.handleNextStep();
        }

    }

    additionTimePopup = () => {
        this.setState({
            openAdditional: true
        })
    }

    handleClose = () => {
        this.setState({
            openAdditional: false
        })
    }

    render() {
        const {
            classes,
            handleSubmit, changeFieldValue, setInitialValue,
            event: { volunteer },
            invalid
        } = this.props;


        const options = []; // {label:'All',value:'all'}


        Array.isArray(volunteer) && volunteer.map((date, DIndex) => {
            date.slotTime.map((slots, SIndex) => {
                options.push({ label: getLabel(date, slots), value: `${DIndex}.${SIndex}` })
                return slots
            })
            return date
        })

        return (
            <div className={classes.root} >
                <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Grid container>
                        <Grid item xs={12} className="centerdiv">
                            <AddSpots
                                options={options}
                                handleSubmit={handleSubmit}
                                setInitialValue={setInitialValue}
                                changeFieldValue={changeFieldValue}
                            />
                            <FieldArray name="volunteer" component={DatesCard} />
                        </Grid>
                    </Grid>

                    <div className={classes.actionButton}>
                        <Button variant="contained" onClick={this.handlePreviousStep}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={invalid}
                        >
                            Next
                        </Button>
                    </div>
                </Form>
            </div >
        );
    }
}

const selector = formValueSelector('generalDetailsForm');

const mapStateToProps = state => {
    return {
        initialValues: state.event.formInitialValue,
        generalFormValues: selector(state, 'volunteertype', 'daterange'),
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setInitialValue: (payload) => dispatch(setInitialValue(payload)),
        changeFieldValue: (field, value) => dispatch(change("generalDetailsForm", field, value))
    }
};

const reminderSingleDate = reduxForm({
    form: "generalDetailsForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Slots);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reminderSingleDate));
