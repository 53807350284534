import React, { Component } from "react";
import { FormControl, FormLabel } from "@material-ui/core";
import RichTextEditor from 'react-rte';
// import { Editor, EditorState } from 'draft-js';


class RenderRichTextEditor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      editorState: RichTextEditor.createValueFromString(props.input.value, 'html'),
      isTextLoad: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if(!this.state.isTextLoad){
      this.setState({
        editorState: RichTextEditor.createValueFromString(nextProps.input.value, 'html'),
        isTextLoad: true
      })
    }
  }

  handleChange = (value) => {
    this.setState({ editorState: value })
    const html = value.toString('html');
    this.props.input.onChange(html)
  }

  render() {
    const { label, row = 5 } = this.props
    const { editorState } = this.state
    return (
      <FormControl component="fieldset">
        <FormLabel className="formLabel">{label}</FormLabel>
        <RichTextEditor
          row={row}
          value={editorState}
          onChange={this.handleChange}
        />
      </FormControl>
    )
  }
}

// export default RenderRichTextEditor


// function RenderRichTextEditor(props) {
//   const { label, input, row = 5 } = props
//   const value = RichTextEditor.createValueFromString(input.value, 'html')
//   const [editorState, setEditiorState] = useState(value)



//   useEffect(() => {
//     setTimeout(() => {
//       console.log(input)
//     }, 10);
//   }, [])

//   return (

//   )
// }

export default RenderRichTextEditor



/*   function RenderDraftEditor(props) {
    const { label, input } = props
    const value = input.value || EditorState.createEmpty();
    const [editorState, setEditorState] = useState(value);

    const editor = React.useRef(null);

    function handleChange(editorState) {
      setEditorState(editorState)
      console.log(editorState.toString('html'))
    }

    function focusEditor() {
      editor.current.focus();
    }

    return (
      <FormControl component="fieldset">
        <FormLabel className="formLabel">{label}</FormLabel>
        <div onClick={focusEditor}>
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={handleChange}
          />
        </div>
      </FormControl>
    );
  } */