export const WeekDays = Object.freeze({
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
    // "Sunday": "su",
    // "Monday": "mo",
    // "Tuesday": "tu",
    // "Wednesday": "we",
    // "Thursday": "th",
    // "Friday": "fr",
    // "Saturday": "sa"
});
