import React, { Component } from 'react'
import Moment from "react-moment";
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import {
    FieldArray,
} from 'redux-form';
import Spot from './Spot';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

const styles = theme => ({

    slot: {
        color: "#3c3989",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    slotContainer: {
        backgroundColor: "#f8f8f8"
    },
    itemDetails: {
        color: "#000000",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    table: {
        minWidth: 620,
        margin: "10px 35px",
        width: "95% !important"
      },
});

class TimeSlotsWithSpot extends Component {
    getStripedStyle = (index) =>{
        return { background: index % 2 ? 'white' : '#f4f4f4' };
      }
    render() {
        const { classes, fields } = this.props
        return (
            <>
            <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {fields.map((slots, index) =>
                    <TableRow key={index} style={{...this.getStripedStyle(index)}}>
                        <TableCell align="left" style={{width: "40%"}}>
                            <Typography variant="h6" className={classes.slot}>
                                <Moment format="hh:mm A">
                                    {fields.get(index).start_time}
                                </Moment>
                                {'-'}
                                <Moment format="hh:mm A">
                                    {fields.get(index).end_time}
                                </Moment>
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <FieldArray name={`${slots}.spot`} component={Spot} />
                        </TableCell> 
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </>
        )
    }
}

export default withStyles(styles)(TimeSlotsWithSpot)