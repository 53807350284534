import {
    action,
} from './common';

export const FILTER_RADIO_CHNAGE = 'FILTER_RADIO_CHNAGE';
export const handleFilterRadioChange = payload => action(FILTER_RADIO_CHNAGE, {  payload});

export const FILTER_SELECT_GROUP = 'FILTER_SELECT_GROUP'
export const handleFilterSelectGroup = payload => action(FILTER_SELECT_GROUP, {  payload});

export const FILTER_SELECT_CHILD = 'FILTER_SELECT_CHILD'
export const handleFilterSelectChild = payload => action(FILTER_SELECT_CHILD, {  payload});