import {
    REQUEST,
    SUCCESS,
    FAILURE,
    action,
    createRequestTypes
} from './common';


export const FETCH_DRAFT_POST = createRequestTypes('FETCH_DRAFT_POST');
export const FETCH_REVIEW_POST = createRequestTypes('FETCH_REVIEW_POST');
export const FETCH_SCHEDULE_POST = createRequestTypes('FETCH_SCHEDULE_POST');

export const fetchDraftPost = {
    request: query => action(FETCH_DRAFT_POST[REQUEST], { query }),
    success: payload => action(FETCH_DRAFT_POST[SUCCESS], { payload }),
    failure: error => action(FETCH_DRAFT_POST[FAILURE], { error }),
}
export const fetchReviewPost = {
    request: query => action(FETCH_REVIEW_POST[REQUEST], { query }),
    success: payload => action(FETCH_REVIEW_POST[SUCCESS], { payload }),
    failure: error => action(FETCH_REVIEW_POST[FAILURE], { error }),
}
export const fetchSchedulePost = {
    request: query => action(FETCH_SCHEDULE_POST[REQUEST], { query }),
    success: payload => action(FETCH_SCHEDULE_POST[SUCCESS], { payload }),
    failure: error => action(FETCH_SCHEDULE_POST[FAILURE], { error }),
}