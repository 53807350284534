import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { ListItemText, MenuItem, Input, Chip } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },

};

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText className="errorText">{touched && error}</FormHelperText>
  }
}

export function renderSelectField(props) {
  const {
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  } = props


  const inputProps = { name: input.name, id: input.id }
  return (
    <FormControl >

      <Select
        {...input}
        {...custom}
        inputProps={inputProps}
      >
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  )
}

export function renderTextField(props) {
  const {
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  } = props;

  return (
    (
      <TextField
        className="fieldSpot"
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    )
  )
}

export function RenderMultiSelect(props) {
  const { options, input, selectedData, meta: { touched, invalid, error }, ...custom } = props;
  const [date, setDate] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);

  useEffect(() => {
    if(selectedData && selectedData.length){
        if(selectedData === ["none"]){
            setDate([]);
            input.onChange([]);
            setCheckedAll(prevValue => !prevValue);
        }else {
            setDate(selectedData);
            input.onChange(selectedData);
            setCheckedAll(prevValue => !prevValue);
        }
    }else{
        const value = options.map(option => option.value);
        setDate(value);
        input.onChange(value);
        setCheckedAll(prevValue => !prevValue);
    }
  }, [])

  function handleSelectAll(event) {
    if (checkedAll) {
      setDate([]);
      input.onChange([]);
    }
    else {
      const value = options.map(option => option.value);
      setDate(value);
      input.onChange(value);
    }
    setCheckedAll(prevValue => !prevValue);
  }

  function handleChange(event, index, menuItem) {
    if (index.props.value === 'all dates') {
      handleSelectAll()
    } else {
      setDate(event.target.value);
      input.onChange(event.target.value)
    }
  }

  return (
    <FormControl >
      <Select
        multiple
        autoWidth={true}
        value={date}
        {...custom}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        MenuProps={{ MenuProps }}
        renderValue={selected => `${options.length === selected.length ? "All dates" : selected && selected.includes("none") ? "" : `${selected.length} dates/time`}`}
        error={touched && invalid}
      >
        <MenuItem value="all dates" >
          <Checkbox checked={checkedAll} />
          <ListItemText primary={checkedAll ? "Select None" : "All Dates"} />
        </MenuItem>

        {
          options.map(option => {
            let optionLabel = <div> {option.label.substr(0, 4)}
              <span style={{color: "#78a0cb"}}> {option && option.label.substr(5,10)}</span> @
              <span style={{color: "#78a0cb"}}> {option && option.label.substr(18,15)}</span>
            </div>
            return (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={date.indexOf(option.value) > -1}/>
                  <ListItemText primary={optionLabel}/>
                </MenuItem>
            )
          })
        }
        {/*{options.map(option => (*/}
        {/*  <MenuItem key={option.value} value={option.value}>*/}
        {/*    <Checkbox checked={date.indexOf(option.value) > -1} />*/}
        {/*    <ListItemText primary={option.label} />*/}
        {/*  </MenuItem>*/}
        {/*))}*/}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  )
}