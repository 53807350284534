import React, { Component } from 'react'
import {
    Typography, Dialog, DialogTitle,
    DialogContent, DialogActions, Grid
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Button } from 'antd';
import {
    FieldArray
} from 'redux-form';
import SpotList from './SpotList';
import {connect} from "react-redux";

const styles = theme => ({
    additional: {
        color: "#797979",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "17px",
        textAlign: "right",
        marginTop: "-50px"
    },
    link: {
        cursor: "pointer",
        padding: "0 20px"
    }
});

class AddSpots extends Component {

    state = {
        openDialog: false,
        dataList: []
    }

    componentDidMount(){
        const {initialValues} = this.props
        if(initialValues.slotTime && initialValues.slotTime[0]){
            this.setState({
                dataList: initialValues.slotTime && initialValues.slotTime[0] && initialValues.slotTime[0].spots || []
            })
        }
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true })
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false })
    }

    handleSpotSubmit = (data) => {
        this.props.setInitialValue({ ...data, volunteer: [], fields: []});
        const values = Object.assign({}, data);
        const { spot, volunteer } = values;
        const { changeFieldValue } = this.props;
        let newVol = volunteer;
        newVol.map(item=>{
            item.slotTime.map(uu =>{
                uu.spot = []
            })
        })
        spot.forEach((item) => {
            item.dates.forEach(date => {
                const indexs = date.split('.');
                newVol = newVol.map((v, vKey) => {
                    if (String(vKey) === indexs[0]) {
                        const slotTime = v.slotTime.map((s, sKey) => {
                            if (String(sKey) === indexs[1]) {
                                if (s.spot) {
                                    return { ...s, spot: [...s.spot, item] };
                                } else {
                                    return { ...s, spot: [item] };
                                }
                            }
                            return s;
                        });
                        return { ...v, slotTime };
                    }
                    return v;
                })
                
            })
        })
        // changeFieldValue(`volunteer`, newVol)
        // changeFieldValue(`spot`, [])
        this.props.setInitialValue({ ...data, volunteer: newVol,spot:[] });
        this.setState({
            spot: spot
        })
        this.handleCloseDialog()
    }

    render() {
        const { classes, handleSubmit, options } = this.props;
        const { openDialog, dataList } = this.state;
        return (
            <>
                <Typography component="div" className={classes.additional}>
                    <span className={classes.link} onClick={this.handleOpenDialog}>+ Add/Edit Spot</span>
                </Typography>
                <Dialog
                    open={openDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="spot-dialog-title"
                    aria-describedby="spot-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="spot-dialog-title">Add Spots</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12} className="centerdiv">
                            <FieldArray spotData={this.state.spot} dataList={dataList} name="spot" component={SpotList} options={options} />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmit(this.handleSpotSubmit)} color="primary">
                            DONE
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        initialValues: state.event.formInitialValue,
    }
};
export default connect(mapStateToProps, null)(withStyles(styles)(AddSpots));