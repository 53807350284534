import React, { Component, Fragment } from 'react';
import {
    withStyles, Icon, Card,
    CardContent, Typography, CardActionArea, Grid,
    IconButton
} from '@material-ui/core';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import { API } from 'aws-amplify';
import classnames from 'classnames';
import * as  ReactHighChart from 'react-highcharts';
import * as routes from '../routes/path';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActionsWrapped from '../component/common/TablePaginationActions ';
import Paper from '@material-ui/core/Paper';
import attendance from '../service/attendance';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        width: 250,
        margin: theme.spacing(1)
    },
    content: {
        width: 250,
    },
    graph_card: {
        width: 520,
        margin: theme.spacing(1)
    },
    graph_content: {
        width: 520,
    },
    chart: {
        margin: 'auto',
        paddingTop: '10px'
    },
    subtext1: {
        clear: 'left',
        margin: 0,
        position: 'relative',
    },
    subtext2: {
        color: '#9e9e9e',
        position: 'absolute',
        right: '-6px',
        top: '-2px',
    },
    table: {
        minWidth: 150,
    },
    paper: {
        padding: theme.spacing(1) * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        color: "white"
    }
});
/* ['Present', 0],['Absent', 0], ['Unmarked', 0] */

const config = {

    title: {
        text: ''
    },
    credits: {
        enabled: false
    },
    chart: {
        polar: false,
        width: 350,
        height: 310,
        textAlign: 'center',
        margin: 0
    },
    series: [{
        type: 'pie',
        name: 'Value',
        innerSize: '50%',
        colors: [
            '#2FBF62',
            '#D64444',
            '#44C0D6',
            '#B0C1C4',
        ],
        dataLabels: {
            enabled: false
        },
        data: [],
        events: {
            click: (events) => { }
        },
    }],
};


class Dashboard extends Component {

    state = {
        count: {
            students: 0,
            classes: 0,
            parents: 0,
            staff: 0
        },
        student: _.cloneDeep({ ...config, title: { text: '' } }),
        staff: _.cloneDeep({ ...config, title: { text: 'staff' } }),
        rows: [],
        page: 0,
        rowsPerPage: 5
    }

    componentDidMount() {
        this.getCount();
        this.customLoader();
        this.getUnMarkedAttendanceData();
        this.getTodaySummary();
    }

    getUnMarkedAttendanceData = async () => {
        const { data } = await attendance.unmarkedAttendance({})
        this.setState({ rows: data });
    }

    getTodaySummary = async () => {
        const { data: value } = await attendance.summaryAttendance({})
        if (value.length) {
            const data = Object.entries(value[0])
            // const data = [['Present', 60], ['Absent', 25], ['Checkout', 15], ['Unmarked', 4]]
            const student = this.state.student;
            student.series[0].data = data;
            this.setState({ student })
        }
    }

    customLoader = () => {
        let count = 1;
        this.interval = setInterval(() => {
            if (count > 4)
                count = 1;
            const val = new Array(count).fill('.').join('');
            this.setState({
                count: {
                    students: val,
                    classes: val,
                    parents: val,
                    staff: val
                }
            });
            count++;
        }, 200);
    }

    getCount = () => {
        Promise.all(
            [API.get('get_students_count', ''), API.get('get_users_count', '')]
        ).then(res => {
            const studentCount = res[0].count;
            const { classes, parents, staff } = res[1].count;
            clearInterval(this.interval);
            this.setState({
                count: {
                    students: studentCount,
                    classes,
                    parents,
                    staff
                }
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { classes, history } = this.props;
        const { rows, page, rowsPerPage } = this.state;
        const { students, classes: ClassCount, parents, staff } = this.state.count;

        const day = moment().format('dddd');
        const isWeekEnd = day === 'Sunday' || day === 'Saturday';
        const unaccountedTotal = !isWeekEnd ? rows.length : 0;

        return (
            <div className={classes.root}>

                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Card className={classes.card}>
                                    <CardActionArea onClick={(e) => { history.push(routes.USERS) }}>
                                        <CardContent className={classes.content}>
                                            <Typography  className={classes.subtext1}>
                                                Add Users (Bulk)
                                            <Icon className={classnames(classes.subtext2)}>call_made</Icon>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container>
                            <Grid item className={classnames("containerPadding", "smallCardContainer")}>
                                <CardActionArea className="cardWidth">
                                    <Grid className="cardInnerLayout" onClick={(e) => { history.push(routes.USER) }}>
                                        <Grid className="cardBody cardBody3 cardBody1 cardBody2">
                                            <Grid variant="headline" className="cardTitle">
                                                Student
                                        </Grid>
                                            <h3 className="cardNumber">
                                                {students}
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Grid>
                            <Grid item className={classnames("containerPadding", "smallCardContainer")}>
                                <CardActionArea className="cardWidth">
                                    <Grid className="cardInnerLayout" onClick={(e) => { history.push(routes.PARENTS) }}>
                                        <Grid className="cardBody cardBody3 cardBody1 cardBody2">
                                            <Grid variant="headline" className="cardTitle">
                                                Parent
                                        </Grid>
                                            <h3 className="cardNumber">
                                                {parents}
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Grid>
                            <Grid item className={classnames("containerPadding", "smallCardContainer")}>
                                <CardActionArea className="cardWidth">
                                    <Grid className="cardInnerLayout" onClick={(e) => { history.push(routes.STAFF) }}>
                                        <Grid className="cardBody cardBody3 cardBody1 cardBody2">
                                            <Grid variant="headline" className="cardTitle">
                                                Staff
                                        </Grid>
                                            <h3 className="cardNumber">
                                                {staff}
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Grid>
                            <Grid item className={classnames("containerPadding", "smallCardContainer")}>
                                <CardActionArea className="cardWidth">
                                    <Grid className="cardInnerLayout" onClick={(e) => { history.push(routes.GROUP) }}>
                                        <Grid className="cardBody cardBody3 cardBody1 cardBody2">
                                            <Grid variant="headline" className="cardTitle">
                                                Groups
                                        </Grid>
                                            <h3 className="cardNumber">
                                                {ClassCount}
                                            </h3>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <Paper className={classnames(classes.paper, "tableHeader")}>
                                <Grid className="tableGrid tableGrid1" item xs={12}>
                                    <h5 className="table">School Attendance
                                </h5>
                                    <IconButton onClick={this.getTodaySummary} className={classes.button} aria-label="AutoRenew">
                                        <AutoRenewIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item className={classes.chart}>
                                    {
                                        !!this.state.student.series[0].data.length &&
                                        <Fragment>
                                            <ReactHighChart config={this.state.student} />
                                            <Link href to={routes.StudentSummary}>Summary{' '}(View More)</Link>
                                        </Fragment>
                                    }
                                    {!this.state.student.series[0].data.length &&
                                        <span>No attendance data available for student</span>
                                    }
                                </Grid>
                                {/* <Grid item className={classes.chart}>
                                {
                                    !!this.state.staff.series[0].data.length &&
                                    <Fragment>
                                        <ReactHighChart config={this.state.staff} />
                                        <Link href to={routes.StaffSummary}>summary</Link>
                                    </Fragment>
                                }
                                {!this.state.staff.series[0].data.length &&
                                    <span>No attendance data available for staff</span>
                                }
                            </Grid> */}
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classnames(classes.paper, "tableHeader")}>
                                <Grid className="tableGrid tableGrid1" item xs={12}>
                                    <h5 className="table">
                                        Unknown Children:(Total kids Unknown {unaccountedTotal})
                                </h5>
                                    <IconButton onClick={this.getUnMarkedAttendanceData} className={classes.button} aria-label="AutoRenew">
                                        <AutoRenewIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Children</TableCell>
                                                <TableCell>Parents1</TableCell>
                                                <TableCell>Parent2</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell >{row.firstname}{' '}{row.lastname}</TableCell>
                                                        <TableCell >
                                                            {row.parent1}
                                                            <br />
                                                            {row.parent1email}
                                                            <br />
                                                            {row.parent1phone}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.parent2}
                                                            <br />
                                                            {row.parent2email}
                                                            <br />
                                                            {row.parent2phone}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={3}
                                                    count={rows.length}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                </Grid >
            </div>
        );
    }
}



export default withStyles(styles)(Dashboard);