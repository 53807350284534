import { action } from "./common";

export const OPEN_DIALOG_BOX = "OPEN_DIALOG_BOX";
export const openDialogBox = payload => action(OPEN_DIALOG_BOX, { payload });

export const CLOSE_DIALOG_BOX ="CLOSE_DIALOG_BOX";
export const closeDialogBox = payload => action(CLOSE_DIALOG_BOX, { payload });

export const SHOW_TOAST_MESSAGE ="SHOW_TOAST_MESSAGE";
export const showToastMessage = payload => action(SHOW_TOAST_MESSAGE, { payload });

export const HIDE_TOAST_MESSAGE ="HIDE_TOAST_MESSAGE";
export const hideToastMessage = payload => action(HIDE_TOAST_MESSAGE, { payload });

export const PAGE_LOADER_START = 'PAGE_LOADER_START';
export const setPageLoaderStart = payload => action(PAGE_LOADER_START, { payload });

export const PAGE_LOADER_FINISH = 'PAGE_LOADER_FINISH';
export const setPageLoaderFinish = payload => action(PAGE_LOADER_FINISH, { payload });