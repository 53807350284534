import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles"
import classnames from "classnames"
import PinIcon from '@material-ui/icons/TouchApp';
// import DatePickerIcon from '@material-ui/icons/DateRange';

import {
    Chip, Grid, Button, TextField, Tooltip, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.1),
        color: 'darkblue',
        cursor: "pointer",
        fontSize: "12px"
    },
    selectedChip: {
        margin: theme.spacing(0.1),
        color: 'red',
        cursor: "pointer",
        fontSize: "12px"
    },
    errorMessage: {
        color: "red"
    }

}));


function PinnedDialog(props) {
    const { pinned_mode, pinned_topic, pinned_until, label } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({ pinned: '', date: '' })
    const [isEnablePinned, setIsEnablePinned] = useState(pinned_mode.input.value === 1 ? true : false)

    useEffect(() => {
        setIsEnablePinned(pinned_mode.input.value === 1 ? true : false)
    }, [pinned_mode])


    function handleClose() {
        setOpen(false)
    }

    function handleOpen() {
        setOpen(true);
    }

    function handleEnablePinnded() {
        if (pinned_topic.input.value && pinned_until.input.value) {
            pinned_mode.input.onChange(1)
            setState({ pinned: '', date: '' })
            setIsEnablePinned(true)
            handleClose()
        } else {
            if (!pinned_until.input.value) {
                setState({ ...state, date: 'pinned_until is Requried' })
            }

            if (!pinned_topic.input.value) {
                setState({ ...state, pinned: 'pinnedtopic is Requried' })
            }

        }
    }

    function handleDisablePinnded() {
        pinned_mode.input.onChange(0)
        setIsEnablePinned(false)
        handleClose()
    }


    return (
        <>
            <Tooltip title="Pinned Post">
                <Chip
                    // icon={<img style={{ fontSize: "medium", marginLeft: '15px' }} src="https://img.icons8.com/ios/15/000000/pin.png" />}
                    icon={<PinIcon style={{ fontSize: "medium", marginLeft: '15px' }} />}
                    label={label}
                    variant="outlined"
                    color="primary"
                    onClick={handleOpen}
                    className={classnames({
                        [classes.chip]: !isEnablePinned,
                        [classes.selectedChip]: isEnablePinned,

                    })}
                />
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="pinned-dialog-title"
                scroll="body"
                disableEscapeKeyDown
                disableBackdropClick
            >
                <DialogTitle id="pinned-dialog-title">
                    <Typography variant="h4">Pinned Post</Typography>

                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container item xs={12} spacing={1} direction="column" >
                        <Grid item >
                            <TextField
                                label="topic"
                                className={classes.textField}
                                variant="outlined"
                                multiline
                                rowsMax="4"
                                {...pinned_topic.input}
                            />
                        </Grid>
                        <Typography variant="caption" className={classes.errorMessage}>
                            {state.pinned}
                        </Typography>
                        <Grid item >
                            <DatePicker
                                {...pinned_until.input}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                minDate={new Date()}
                                format="YYYY-MM-DD"
                                label="pinned_until"
                            />
                        </Grid>
                        <Typography variant="caption" className={classes.errorMessage}>
                            {state.date}
                        </Typography>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisablePinnded} color="primary">
                        Disable
                </Button>
                    <Button onClick={handleEnablePinnded} color="primary">
                        Enable
                </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}

export default PinnedDialog
