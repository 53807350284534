import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    withStyles, Grid, Card, Avatar,
    Typography, Button, Input, Select, MenuItem
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { API } from 'aws-amplify';
import { Popconfirm, message } from 'antd';
import CustomLoadingOverlay from "../component/common/customLoadingOverlay.jsx";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import studentService from '../service/student';
import { fetchChildListSuccess } from '../redux/actions/child';
import * as routes from '../routes/path';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    row: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(1) * 2,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1) * 2,
    },
    select: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },

});

class Students extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            category: 'all',
            columnDefs: this.createColumnDefs(),
            frameworkComponents: {
                customLoadingOverlay: CustomLoadingOverlay,
            },
            loadingOverlayComponent: "customLoadingOverlay",
            loadingOverlayComponentParams: { loadingMessage: "Loading Students.... Please wait.." },
            onGridReady: this.onGridReady,
            rowData: [],
            currentStudent: {},
            currentParent: {},
            openParent: false,
            style: {
                width: '100%',
                height: '100%'
            }
        }
    }

    componentDidMount() {
        this.fetchStudents();

    }

    createColumnDefs() {
        return [
            {
                headerName: "Profile",
                field: "pictureurl",
                autoHeight: true,
                cellRendererFramework: this.profilePictureRenderer,
                width: 80
            },
            { headerName: "First Name", field: "firstname", width: 100 },
            { headerName: "Last Name", field: "lastname", width: 100 },
            {
                headerName: "Parents",
                field: "parents",
                cellRendererFramework: this.parentColumnRender,
                autoHeight: true
            },
            { headerName: "DOB", field: "dob", width: 100 },
            { headerName: "Allergies", field: "allergies", width: 100 },
            { headerName: "Groups", field: "groups", width: 100, cellRendererFramework: this.groupNameRender },
            {
                headerName: "Action",
                field: "childid",
                width: 150,
                cellRendererFramework: this.actionColumnRenderer,
                autoHeight: true
            },
        ]
    }

    groupNameRender = event => {
        const { currentUser: { userInfo: { Groups } } } = this.props;

        return (
            <Fragment>
                {event.data && event.data.groups.map((group, index) => (

                    <div key={index}>
                        {Groups[group] !== undefined ? Groups[group].GroupName.replace("_", " ") : ""}
                    </div>
                ))}
            </Fragment>
        )
    }

    profilePictureRenderer = event => {
        const { classes } = this.props;
        return (
            <Avatar className={classes.avatar}>{event.data.firstname[0].toUpperCase()}</Avatar>
        )
    }

    parentColumnRender = event => {
        return (
            <Fragment>
                {event.data && event.data.parents.map((parent, index) => (
                    <div key={index}>
                        <button className="btn btn-link" onClick={e => { this.onParentEmailClick(parent) }}>
                            {parent}
                        </button>
                    </div>
                ))}
            </Fragment>
        )
    }

    actionColumnRenderer = event => {
        return (
            <div>
                <button type="button" className="btn btn-link" onClick={(e) => this.handleClickOpen(event)}>edit</button>
                <Popconfirm title="Are you sure delete this task?" onConfirm={() => this.deleteChild(event)} okText="Yes" cancelText="No">
                    <button type="button" className="btn btn-link">delete</button>
                </Popconfirm>
            </div>
        )
    }

    fetchStudents = async () => {
        this.startLoading();
        const { dispatch } = this.props;
        const { data } = await studentService.getStudentList('all');
        this.setState({ rowData: data });
        dispatch(fetchChildListSuccess(data))
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
        this.stopLoading();
    }

    deleteChild = event => {
        const childId = event.value;
        if (!childId) return;
        API.post('delete_student', '', {
            body: {
                childid: childId
            }
        }).then(res => message.success('user Deleted'))
            .then(() => this.fetchStudents())
            .catch(err => console.log(err));
    }

    onParentEmailClick = username => {
        this.props.history.push(routes.PARENTS_EDIT, { username })
    }

    search = ev => {
        const value = ev.target.value ? ev.target.value.toString().trim() : '';
        this.gridApi.setQuickFilter(value);
    }

    handleSelectChange = (event) => {
        const { name, value } = event.target;
        const { child: { childList } } = this.props;
        this.setState({ [name]: value });
        if (value === 'all') {
            this.setState({ rowData: childList });
        } else {
            const data = childList && childList.filter(child => child.groups.some(group => value === group));
            this.setState({ rowData: data });
        }
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
    }

    onClickAddStudent = event => {
        this.props.history.push(routes.USERS_ADD)
    }

    handleClickOpen = (event) => {
        const { data } = event;
        const { childid } = data
        this.props.history.push(routes.USERS_EDIT, { childid })
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();

        this.startLoading();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }

    startLoading = () => {
        if (this.gridApi)
            this.gridApi.showLoadingOverlay();
    }

    stopLoading = () => {
        this.gridApi.hideOverlay();
    }

    excelExport() {
        const params = {
            columnKeys: ['firstname', 'lastname', 'parents', 'dob', 'allergies'],
            //processCellCallback : this.formattingFunction
        }
        this.gridApi.exportDataAsCsv(params);
    }

    formattingFunction = (params, Groups) => {
        if (params.column.getColId() === 'groups' && params.value) {
            return Groups[params.value] !== undefined ? Groups[params.value].GroupName.replace("_", " ") : "";
        } else {
            return params.value;
        }
    }

    render() {
        const { classes, currentUser: { userInfo } } = this.props;
        const { style } = this.state;


        return (
            <div className={classes.root}>
                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card className={classNames(classes.paper)}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography className={classes.typography} variant="h6" gutterBottom>Student Dashboard</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        className={classes.select}
                                        value={this.state.category}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="category" id="category-helper" />}
                                    >
                                        <MenuItem value='all'>All Groups</MenuItem>
                                        {userInfo.Groups && Object.keys(userInfo.Groups).map((key, index) => (
                                            <MenuItem value={userInfo.Groups[key].groupid} key={index}>{userInfo.Groups[key].GroupName}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={7} >
                                    <Input placeholder="Search" onChange={this.search} className={classes.input} inputProps={{ 'aria-label': 'Description', }} />
                                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.onClickAddStudent}>
                                        Add Student
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card >
                            <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.excelExport.bind(this)}>Download</Button>
                            <div id="myGrid" className="ag-theme-balham" style={style}>
                                <AgGridReact
                                    frameworkComponents={this.state.frameworkComponents}
                                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                                    loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                    animateRows={true}
                                    enableFilter={true}
                                    enableColResize={true}
                                    gridAutoHeight={true}
                                    onColumnResized={this.onColumnResized.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onGridReady={this.state.onGridReady}
                                />
                            </div>
                        </Card>
                    </Grid>
                </Grid>

            </div >
        );
    }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(withStyles(styles)(Students));