import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from "@material-ui/core/Checkbox";

const CheckBoxs = ({ input, meta, options }) => {
  const { name, onChange } = input;
  const { touched, error } = meta;
  const inputValue = input.value || [];

  const checkboxes = options.map(({ label, value }, index) => {

    const handleChange = (event) => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(value);
      }
      else {
        arr.splice(arr.indexOf(value), 1);
      }
      return onChange(arr);
    };
    
    const checked = inputValue.includes(value);
    return (
      <label key={`checkbox-${index}`}>
        <Checkbox
          name={`${name}[${index}]`}
          checked={checked}
          onChange={handleChange}
        />
        <span>{label}</span>
      </label>
    );
  });

  return (
    <div>
      <div>{checkboxes}</div>
      {touched && error && <p className="error">{error}</p>}
    </div>
  );
};

export default class CheckboxGroup extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  };

  render() {
    return <CheckBoxs {...this.props} />;
  }
}