import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import Chip from '@material-ui/core/Chip';
import AsyncImage from './AsyncImage';

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export default function Previews(props) {
  const initialFile = props.input.value || [];
  const acceptedFileType = props.accept;
  const imagePreview = props.imagePrev;
  const allowMultiple = props.multiple;
  const linkName = props.linkName;
  const [files, setFiles] = useState(initialFile);
  const { getRootProps, getInputProps, } = useDropzone({
    accept: acceptedFileType,
    multiple: allowMultiple,
    onDrop: handleDrop
  });


  useEffect(() => {
    setFiles(props.input.value)
  }, [props.input])

  async function handleDrop(acceptedFiles) {
    const file = await Promise.all(acceptedFiles.map(uploadS3))
    setFiles(file);
    props.input.onChange(file);
  }

  async function uploadS3(file) {
      const path = Date.now();
      const response = await Storage.put(path, file, { contentType: file.type });
      return Object.assign({ key: response.key, name: file.name });
  }

  function handleDelete(file) {
    const result = files.filter(_file => _file !== file)
    setFiles(result)
    props.input.onChange(result);
  }

  function thumbs() {
    return files && files.map((file, index) => (
      <div key={index} className="previewImg customColumn">
        {imagePreview === true &&
          (<div className="">
            <AsyncImage src={file.key} style={img} className="imagePrev" alt="preview" />
          </div>)
        }
        {imagePreview === false &&
          (<Chip
            label={file.name}
            color="primary"
            variant="outlined"
            onDelete={e => { handleDelete(file) }}
          />)}
      </div>
    ));
  }

  return (
    <section className="container">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-btn-wrapper">
          <button className="btn">{linkName}</button>
        </div>
      </div>
      <div className="previewSection customRow">
        {thumbs()}
      </div>
    </section>
  );
}



