import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

const propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    padding0: {
        padding: 0,
    },
    menuWrp: {
        zIndex: 1
    }
});

class PopOverMenu extends React.Component {
    state = {
        anchorElPostMenu: null
    }

    handlePostMenu = event => {
        this.setState({ anchorElPostMenu: event.currentTarget });
    };

    handlePostClose = () => {
        this.setState({ anchorElPostMenu: null });
    };

    render() {
        const { anchorElPostMenu } = this.state;
        const { classes, icon } = this.props;
        const openPostMenu = Boolean(anchorElPostMenu);
        const id = openPostMenu ? 'popover' : null;

        return (
            <Fragment>
                <IconButton
                    aria-owns={openPostMenu ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handlePostMenu}
                    color="inherit">
                    {icon}
                </IconButton>
                <Popover
                    id={id}
                    anchorEl={anchorElPostMenu}
                    open={openPostMenu}
                    onClose={this.handlePostClose}
                    className={classes.menuWrp}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Fragment>
                        <MenuList className={classes.padding0}>
                            <MenuItem onClick={this.props.handleEditPost}>Edit</MenuItem>
                        </MenuList>
                        <MenuList className={classes.padding0}>
                            <MenuItem onClick={this.props.handleDeletePost}>Delete</MenuItem>
                        </MenuList>
                    </Fragment>
                </Popover>
            </Fragment>
        )
    }
}

PopOverMenu.propTypes = propTypes;

export default withStyles(styles)(PopOverMenu);