import { FETCH_STAFF_LIST_SUCCESS, FETCH_STAFF_LIST_FAILURE } from "../actions/staff";


const INITIAL_STATE = {
    staffList: [],
    error: null,
    isLoading: false,
}

const staff = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_STAFF_LIST_SUCCESS:
            return {
                ...state,
                staffList: action.data,
            }
        case FETCH_STAFF_LIST_FAILURE:
            return state
        default:
            return state
    }
}

export default staff;