import { initialize } from "redux-form";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FeedPost from '../component/FeedCard/FeedPost';
import {
  fetchPost, deletePost,
  saveComment, updateComment, deleteComment,
  unlikePost, likePost,
  fetchPinnedPost,
  getPostView
} from '../redux/actions/feed-post';
import { FeedSelector } from '../redux/selector';
import { openDialogBox } from '../redux/actions/component';
import {fetchUserCatch} from "../redux/actions/cache";


const mapStateToProps = state => ({
  currentUser: state.currentUser,
  userGroups: state.currentUser.userInfo.Groups ?
    Object.values(state.currentUser.userInfo.Groups) : [],
  post: {
    feeds: FeedSelector.getPostsWithUser(state),
    query: state.feed.query,
  },
  cache: state.cache,
  pinned: {
    feeds: FeedSelector.getPinnedPostsWithUser(state),
    query: state.feed.pinned.query,
  },
});

const mapDispatchToProps = dispatch => ({
  fetchPostAction: bindActionCreators(fetchPost, dispatch),
  fetchPinnedPostAction: bindActionCreators(fetchPinnedPost, dispatch),
  deletePostAction: bindActionCreators(deletePost, dispatch),
  saveCommentAction: bindActionCreators(saveComment, dispatch),
  updateCommentAction: bindActionCreators(updateComment, dispatch),
  deleteCommentAction: bindActionCreators(deleteComment, dispatch),
  postViewAction: bindActionCreators(getPostView, dispatch),
  likePostAction: bindActionCreators(likePost, dispatch),
  unlikePostAction: bindActionCreators(unlikePost, dispatch),
  openDialogAction: bindActionCreators(openDialogBox, dispatch),
  formInitializeAction: bindActionCreators(initialize, dispatch),
  getUserCatch: bindActionCreators(fetchUserCatch, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedPost);

