import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import * as routes from '../../../routes/path';

import Calendar from '../../../assets/images/calendar.png';
import Invitation from '../../../assets/images/invitation.png';
import Meeting from '../../../assets/images/meeting.png';
import Pencil from '../../../assets/images/pencil-2.png';
import Volunteer from '../../../assets/images/volunteer.png';

const styles = {
    root: {
        flexGrow: 1,
        marginBottom: "20px",
    },
    poperview: {
        zIndex: 9
    },
    menuImg: {
        marginRight: "20px"
    },
    menuItem: {
        padding: "30px",
    },
    toolbarText: {
        justifyContent: 'center',
        backgroundColor: "#FFF"
    },
    new: {
        marginTop: "5px",
        marginLeft: "10px",
        color: "#05B9A4",
        cursor: "pointer"
    },
    event: {
        color: "#3C3989",
        fontWeight: "bolder"
    }
};

class EventHead extends React.Component {
    state = {
        open: false,
    };
    onClickAddEvemt = event => {
        this.props.history.push(routes.MANAGE_EVENT)
    }

    handleToggle = () => {
        if(this.props.subTitle==="New")
            this.setState(state => ({ open: !state.open }));
        else
            this.props.history.push(routes.EVENT)
    };

    handleClose = event => {
        this.setState({ open: false });
    };
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Toolbar className={classes.toolbarText}>
                        <Typography color="inherit" className={classes.event}>
                            {this.props.title}
                        </Typography>
                        <div className={classes.new}>
                            <Typography onClick={this.handleToggle} variant="subtitle2" color="inherit" noWrap className={classes.header}>
                                {this.props.subTitle}
                            </Typography>
                            <Popper open={this.state.open} anchorEl={this.anchorEl} transition disablePortal className={classes.poperview}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <MenuItem onClick={this.onClickAddEvemt} className={classes.menuItem}><img src={Calendar} className={classes.menuImg} alt="event"/>Event</MenuItem>
                                                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><img src={Invitation} className={classes.menuImg} alt="invitation"/>Invitation</MenuItem>
                                                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><img src={Volunteer} className={classes.menuImg} alt="volunteer request"/>Volunteer Request</MenuItem>
                                                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><img src={Meeting} className={classes.menuImg} alt="parent teacher conference"/>Parent Teacher Conference</MenuItem>
                                                    <MenuItem onClick={this.handleClose} className={classes.menuItem}><img src={Pencil} className={classes.menuImg} alt="home work"/>Home Work</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}
EventHead.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EventHead));