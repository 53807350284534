import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Upload, Icon, Card, Button, message, Table, notification } from 'antd';
import { Storage } from 'aws-amplify';
import { API } from 'aws-amplify';
import { fetchBulkUsersList } from '../redux/actions/users';
import Student_Bulk_Upload from '../assets/Student_Bulk_Upload.xlsx';

const { Dragger } = Upload;

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bulkData: [],
            showTable: false,
            tableLoading: false
        };
    }

    allowedFileExt = /(\.xls|\.xlsx)$/i;
    columns = [];

    initColumns = () => {
        this.columns = [
            {
                title: 'First Name',
                dataIndex: 'StudentFirstName',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'Last Name',
                dataIndex: 'StudentLastName',
                width: 150,
                fixed: 'left'
            },
            {
                title: 'DOB',
                dataIndex: 'StudentDOB',
                width: 150
            },
            {
                title: 'Allertgies',
                dataIndex: 'StudentAllergies',
                width: 150
            },
            {
                title: 'Parent1',
                children: [
                    {
                        title: 'First Name',
                        dataIndex: 'Parent1FirstName',
                        width: 150
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'Parent1LastName',
                        width: 150
                    },
                    {
                        title: 'Email',
                        dataIndex: 'Parent1Email',
                        width: 200
                    },
                    {
                        title: 'Phone',
                        dataIndex: 'Parent1Phone',
                        width: 150
                    },
                    {
                        title: 'Status',
                        dataIndex: 'Parent1Status',
                        width: 150
                    },
                ]
            }, {
                title: 'Parent2',
                children: [
                    {
                        title: 'First Name',
                        dataIndex: 'Parent2FirstName',
                        width: 150
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'Parent2LastName',
                        width: 150
                    },
                    {
                        title: 'Email',
                        dataIndex: 'Parent2Email',
                        width: 200
                    },
                    {
                        title: 'Phone',
                        dataIndex: 'Parent2Phone',
                        width: 150
                    },
                    {
                        title: 'Status',
                        dataIndex: 'Parent2Status',
                        width: 150
                    }
                ]
            }

        ];
    }

    handlePreUploadAction = file => {
        return new Promise((resolve, reject) => {
            if (!this.beforeUpload(file)) {
                return reject('file must be smaller than 5 mb!');
            }
            resolve();
        });
    }

    showData = () => {
        if (this.state.showTable) {
            this.setState({
                showTable: false,
                tableLoading: false
            });
            return;
        }
        if (!this.props.fileName) {
            notification.error({
                message: 'Oops!!',
                description: 'No previous status found! Try uploading a new file!'
            });
            return;
        }
        this.setState({
            showTable: true,
            tableLoading: true
        });
        API.post('bulupload_status', '', {
            body: {
                "filekey": this.props.fileName
            }
        }).then(res => {
            const data = res.data.Results.map((data, index) => { return { ...data, key: index } });
            this.initColumns();
            this.setState({
                bulkData: data,
                tableLoading: false
            });
        });
    }

    beforeUpload = file => {
        const isValidExtension = this.allowedFileExt.test(file.name);
        if (!isValidExtension) {
            message.error('You can only upload .xls or .xlsx file!');
        }
        const isLt5M = file.size < 5024000;
        if (!isLt5M) {
            message.error('File must be smaller than 5MB!');
        }
        return isValidExtension && isLt5M;
    }

    processFile = filekey => {
        return API.post('bulupload', '', {
            body: { filekey }
        });
    }

    getFileName = (fileName) => {
        if (fileName) {
            return `${fileName.split('.')[0]}${Date.now()}.${fileName.split('.')[1]}`;
        }
        return 'SampleFile';
    }

    uploadFile = data => {
        const { dispatch } = this.props;
        const fileName = this.getFileName(data.file.name);
        dispatch(fetchBulkUsersList(fileName));
        this.handlePreUploadAction(data.file).then(fileContent => {
            Storage.put(`admin/upload/${fileName}`, data.file, {
                contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
                .then(result => this.processFile(fileName))
                .then(res => data.onSuccess(res, data))
                .then(() => { notification.success({ message: 'File Uploaded Successfully!', description: 'Please wait until we process the file, Check the status of the file by clicking on previous status' }) });
        });
    }

    render() {

        const uploadOptions = {
            name: 'file',
            multiple: false,
            beforeUpload: this.handlePreUploadAction,
            customRequest: this.uploadFile
        };

        return (
            <div>
                <Card>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>Bulk Upload Users</h1>
                        <Button onClick={this.showData} icon='sync'>{this.state.showTable ? 'Upload File' : 'Previous Status'}</Button>
                    </div>
                    <span>Download sample file <a download={true} href={Student_Bulk_Upload}>here</a></span>
                    {
                        !this.state.showTable && <Dragger {...uploadOptions} style={{ margin: '10px' }}>
                            <p className='ant-upload-drag-icon'>
                                <Icon type='inbox' />
                            </p>
                            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                            <p className='ant-upload-hint'>Upload file to bulk upload students</p>
                        </Dragger>
                    }
                    {this.state.showTable &&
                        <Table
                            bordered
                            loading={this.state.tableLoading}
                            columns={this.columns}
                            scroll={{ x: 2200, y: 500 }}
                            dataSource={this.state.bulkData} />
                    }
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        fileName: state.user.userList
    }
}

export default connect(mapStateToProps)(Users);
