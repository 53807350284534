import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function RenderTabs(props) {
  const { input, className, tabClassName } = props;

  function handleTabChange(event, newValue) {
    input.onChange(newValue);
  }


  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      centered
      onChange={handleTabChange}
      value={input.value || '1'}
      className={className}
    >
      <Tab className={tabClassName} value={'1'} label="Single Date" />
      <Tab className={tabClassName} value={'2'} label="Multiple Dates" />
      <Tab className={tabClassName} value={'3'} label="Date Range" />
      <Tab className={tabClassName} value={'4'} label="No Date" />
    </Tabs>

  );

}
export default RenderTabs;