import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import DateTimeSlotCardonDate from "./DateTimeSlotCard.noDate";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/styles";
import { change } from "redux-form";
import store from "../../../redux/store";

const useStyles = makeStyles(theme => ({
  dateTimeCard: {
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "12px"
  },
  tabroot: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold"
  },
  tabs: {
    marginTop: "-30px"
  }
}));

export default function CalendarNoDate(props) {
  const classes = useStyles();
  const { fields } = props;
  const dates = fields.getAll()
  const initialDates = Array.isArray(dates) ? dates.map(field => field.event_date) : [];
  const INITIAL_STATE = { noDateSelecteddate: initialDates };
  const [state, setState] = React.useState(INITIAL_STATE);

  function handleDayClick(day, { selected, disabled }) {
    if (disabled) {
      return
    }
    if (state.noDateSelecteddate.length === 0) {
      fields.push({ event_date: day,slotTime:[{start_time:day,end_time:day}] });
    } else {
      store.dispatch(
        change("generalDetailsForm", `volunteer[0].event_date`, day)
      );
    }
    setState({
      noDateSelecteddate: selected ? [undefined] : [day]
    });
  }

  return (
    <>
      <Card className={classes.dateTimeCard}>
        <DayPicker
          numberOfMonths={2}
          selectedDays={state.noDateSelecteddate[0]}
          onDayClick={handleDayClick}
          disabledDays={{ before: new Date() }}
        />
      </Card>
      {state.noDateSelecteddate.length !== 0 && (
        <DateTimeSlotCardonDate
          fields={fields}
          noDateSelecteddate={state.noDateSelecteddate}
        />
      )}
    </>
  );
}
