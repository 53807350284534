import {
    FILTER_RADIO_CHNAGE,
    FILTER_SELECT_GROUP,
    FILTER_SELECT_CHILD,
} from "../actions/filter-post";


const INITIAL_STATE = {
    filtertype: 'group',
    groups: [],
    childs :[],
}

const filterPost = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILTER_RADIO_CHNAGE:
            return {
                ...state,
                filtertype: action.payload
            }
        case FILTER_SELECT_GROUP:
            return {
                ...state,
                groups: action.payload
            }
        case FILTER_SELECT_CHILD:
            return {
                ...state,
                childs: action.payload
            }
        default:
            return state
    }
}

export default filterPost;