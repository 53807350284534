import React, { Component } from "react";
import { Grid, Typography, Button, MenuItem, InputLabel, Icon, FormLabel } from "@material-ui/core/";
import { Field } from "redux-form";
import { renderTextField, renderSelectField, RenderMultiSelect } from "./Form/all";
import { withStyles } from '@material-ui/core/styles';
import { required, number } from '../../../../component/CreatePost/reduxForm/validation'

const styles = theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: '5%'
  },
  nextButton: {
    width: '5%'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  section1: {
    marginTop: "-20px",
    height: "220px",
    backgroundColor: "#F2F2F2"
  },
  section2: {
    marginTop: "-65px",
  },
  section3: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepper: {
    backgroundColor: "#F2F2F2"
  },
  fieldSelectSpot: {
    "& div": {
      width: "100%",
      paddingLeft: "5px",
      paddingRight: "5px"
    }
  },
  fieldCommentSpot: {
    width: "100%",
    "& div": {
      width: "100%"
    }
  }
});

class SpotList extends Component {
  componentDidMount() {
    const { fields, dataList } = this.props;
    if((dataList && dataList.length) && !fields.getAll()){
        dataList.map(data =>{
            if(Object.keys(data) && Object.keys(data).length){
                this.props.fields.push({
                    person: data.person || "",
                    type: data.type || "",
                    description: data.description || "",
                    dates: data.dates || [],
                    comment: data.comment || "",
                });
            }
        })
    }else if (!fields.length) {
      fields.push({person:1,type:'Bring'});
    }
  }

  componentWillReceiveProps(nextProps) {
    const {spotData} = this.props
    if((Array.isArray(nextProps.spotData) && nextProps.spotData.length) && spotData !== nextProps.spotData){
      nextProps && nextProps.spotData && nextProps.spotData.map(data =>{
        this.props.fields.push({
          person: data.person || "",
          type: data.type || "",
          description: data.description || "",
          dates: data.dates || [],
          comment: data.comment || "",
        });
      })
    }
  }

  addSportElement = () => {
    this.props.fields.push({type: "Bring"});
  };

  render() {
    const { fields, classes, spotData } = this.props;
    return (
      <>
        <Grid container>
          {fields.map((spot, index) => {
            let dateSelect = spotData && spotData[index] ? (spotData[index].dates && spotData[index].dates.length) ? spotData[index].dates : ["none"] : []
            return(
                <React.Fragment key={index}>
                    <Grid container item xs={12} style={{marginTop:15}}>
                        <Grid item xs={1} >
                            <Field
                                name={`${spot}.person`}
                                component={renderTextField}
                                validate={[required, number]}
                                className="fieldSpot"
                                placeholder="Persons"
                            />
                        </Grid>
                        <Grid item xs={2} >
                            <FormLabel style={{padding: "6px 0px 7px", color: "black"}}>Person To</FormLabel>
                            {/*<Typography variant="caption" display="block" >Person To</Typography>*/}
                        </Grid>
                        <Grid item xs={2} className={classes.fieldSelectSpot}>
                            <Field
                                name={`${spot}.type`}
                                component={renderSelectField}
                                validate={[required]}
                                className="fieldSelectSpot"
                                placeholder="type"
                            >
                                <MenuItem value="" disabled>
                                    Type
                                </MenuItem>
                                <MenuItem value="Bring">Bring</MenuItem>
                                <MenuItem value="Todo">Todo</MenuItem>
                            </Field>
                        </Grid>

                        <Grid item xs={3} >
                            <Field
                                name={`${spot}.description`}
                                component={renderTextField}
                                validate={[required]}
                                placeholder="What would you like"
                            />
                            <FormLabel style={{padding: "6px 10px 7px", color: "black"}}> On</FormLabel>
                        </Grid>

                        <Grid item xs={3} className={classes.fieldSelectSpot}>
                            <Field
                                name={`${spot}.dates`}
                                component={RenderMultiSelect}
                                selectedData={dateSelect}
                                options={this.props.options}
                                validate={[required]}
                                className="fieldSelectSpot"
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <Button onClick={() => { fields.remove(index) }} color="primary">
                                <Icon>delete</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.fieldCommentSpot}>
                        <Grid item xs={8}>
                            <Field
                                name={`${spot}.comment`}
                                component={renderTextField}
                                className="fieldCommentSpot"
                                placeholder="Comments"
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
          })}
          <Grid item xs={1}>
            <Button onClick={this.addSportElement} color="primary">
              ADD
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(SpotList);
