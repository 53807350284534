const validate = values => {
    const errors = {}

    if (!values.volunteer || !values.volunteer.length) {
        errors.volunteer = { _error: 'At least one Date must be entered' }
        // throw new SubmissionError({ _error: 'At least one Date must be entered' })
        // errors.volunteer = { _error: 'At least one Date must be entered' }
    } else {
        const volunteersArrayErrors = []
        values.volunteer.forEach((date, memberIndex) => {
            const memberErrors = {}
            if (!date || !date.event_date) {
                memberErrors.event_date = 'Required'
                volunteersArrayErrors[memberIndex] = memberErrors
            }

            if (!date || !date.slotTime.length) {
                errors.volunteer = { _error: 'At least one Time must be entered' }
                // throw new SubmissionError({ _error: 'At least one TIme must be entered' })
                // memberErrors.slotTime = { _error: 'At least one Time must be entered' }
                // volunteersArrayErrors[memberIndex] = memberErrors
            }
            const soltArrayError = []
            date.slotTime.forEach((slot, slotIndex) => {
                const slotsErrors = {}
                if (!slot || !slot.spot || !slot.spot.length) {
                    errors.volunteer = { _error: 'At least one Spot must be entered' }
                    // throw new SubmissionError({ _error: 'At least one Spot must be entered' })
                    // slotsErrors.slotTime = { _error: 'At least one spot must be entered' }
                    // soltArrayError[slotIndex] = slotsErrors
                }
            })

            if (soltArrayError.length) {
                volunteersArrayErrors[memberIndex] = soltArrayError
            }
        })


        if (volunteersArrayErrors.length) {
            errors.volunteer = volunteersArrayErrors
        }
    }
    return errors
}

export default validate