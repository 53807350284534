import {
    EDIT_POST_CONTENT,
    FETCH_STAFF,
    FETCH_STUDENTS
} from "../actions/post-manage";
import {
    SUCCESS,
    REQUEST,
} from "../actions/common";

const INITIAL_STATE = {
    staffList: [],
    studentsList: [],
}

const postManage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDIT_POST_CONTENT:
                {
                    return {
                        ...state,
                    }
                }
        case FETCH_STAFF[REQUEST]:
        case FETCH_STUDENTS[REQUEST]:
            return state;
        case FETCH_STAFF[SUCCESS]:
            return {
                ...state,
                staffList: action.payload
            }
        case FETCH_STUDENTS[SUCCESS]:
            return {
                ...state,
                studentsList: action.payload
            }
        default:
            return state
    }
}

export default postManage;