import {
    action,
    createRequestTypes,
    REQUEST,
    SUCCESS,
    FAILURE
} from './common';

export const SET_STEPPER_ACTIVE_STEP = 'SET_STEPPER_ACTIVE_STEP';
export const resetStepperActiveStep = () => setStepperActiveStep(1);
export const setStepperActiveStep = payload => action(SET_STEPPER_ACTIVE_STEP, { payload });

export const SET_INITIAL_VALUE = 'SET_INITIAL_VALUE';
export const setInitialValue = payload => action(SET_INITIAL_VALUE, { payload })

export const SET_CURRENT_EVENT_ID = 'SET_CURRENT_EVENT_ID';
export const setCurrentEventId = payload => action(SET_CURRENT_EVENT_ID, { payload })

export const RESET_FORM_INITAL_VALUE = 'RESET_FORM_INITAL_VALUE';
export const handleFormInitalValue = payload => action(RESET_FORM_INITAL_VALUE, { payload });

export const FILTER_RSVP_RECORD = 'FILTER_RSVP_RECORD';
export const handleRsvpFilterTab = payload => action(FILTER_RSVP_RECORD, { payload });

export const SEARCH_RSVP_RECORD = 'SEARCH_RSVP_RECORD';
export const handleRsvpSearchRecord = payload => action(SEARCH_RSVP_RECORD, { payload });

export const ADD_EVENT = createRequestTypes('ADD_EVENT');
export const addEvent = {
    request: query => action(ADD_EVENT[REQUEST], { query }),
    success: payload => action(ADD_EVENT[SUCCESS], { payload }),
    failure: error => action(ADD_EVENT[FAILURE], { error }),
}

export const UPDATE_EVENT = createRequestTypes('UPDATE_EVENT');
export const updateEvent = {
    request: query => action(UPDATE_EVENT[REQUEST], { query }),
    success: payload => action(UPDATE_EVENT[SUCCESS], { payload }),
    failure: error => action(UPDATE_EVENT[FAILURE], { error }),
}

export const DELETE_EVENT = createRequestTypes('UPDATE_EVENT');
export const deleteEvent = {
    request: query => action(DELETE_EVENT[REQUEST], { query }),
    success: payload => action(DELETE_EVENT[SUCCESS], { payload }),
    failure: error => action(DELETE_EVENT[FAILURE], { error }),
}


export const GET_EVENT = createRequestTypes('GET_EVENT');
export const getEvent = {
    request: query => action(GET_EVENT[REQUEST], { query }),
    success: payload => action(GET_EVENT[SUCCESS], { payload }),
    failure: error => action(GET_EVENT[FAILURE], { error }),
}

export const GET_EVENT_BY_ID = createRequestTypes('GET_EVENT_BY_ID');
export const getEventById = {
    request: query => action(GET_EVENT_BY_ID[REQUEST], { query }),
    success: payload => action(GET_EVENT_BY_ID[SUCCESS], { payload }),
    failure: error => action(GET_EVENT_BY_ID[FAILURE], { error }),
}

export const RSVP_RESPONSE_TYPE = createRequestTypes('RSVP_RESPONSE_TYPE')
export const setRsvpResponseType = {
    request: query => action(RSVP_RESPONSE_TYPE[REQUEST], { query }),
    success: payload => action(RSVP_RESPONSE_TYPE[SUCCESS], { payload }),
    failure: error => action(RSVP_RESPONSE_TYPE[FAILURE], { error }),
}

export const UPDATE_RSVP_RESPONSE_TYPE = createRequestTypes('UPDATE_RSVP_RESPONSE_TYPE')
export const updateRsvpResponseType = {
    request: query => action(UPDATE_RSVP_RESPONSE_TYPE[REQUEST], { query }),
    success: payload => action(UPDATE_RSVP_RESPONSE_TYPE[SUCCESS], { payload }),
    failure: error => action(UPDATE_RSVP_RESPONSE_TYPE[FAILURE], { error }),
}

export const GET_EVENT_RESPONSE = createRequestTypes('GET_EVENT_RESPONSE')
export const fetchEventResponse = {
    request: query => action(GET_EVENT_RESPONSE[REQUEST], { query }),
    success: payload => action(GET_EVENT_RESPONSE[SUCCESS], { payload }),
    failure: error => action(GET_EVENT_RESPONSE[FAILURE], { error }),
}

export const FETCH_RSVP_EVENT_BY_USER = createRequestTypes('FETCH_RSVP_EVENT_BY_USER')
export const fetchUserRSVPEvent = {
    request: query => action(FETCH_RSVP_EVENT_BY_USER[REQUEST], { query }),
    success: payload => action(FETCH_RSVP_EVENT_BY_USER[SUCCESS], { payload }),
    failure: error => action(FETCH_RSVP_EVENT_BY_USER[FAILURE], { error }),
}

export const FETCH_FILTER_BY_USER = createRequestTypes('FETCH_FILTER_BY_USER')
export const fetchFilterEvent = {
    request: query => action(FETCH_FILTER_BY_USER[REQUEST], { query }),
    success: payload => action(FETCH_FILTER_BY_USER[SUCCESS], { payload }),
    failure: error => action(FETCH_FILTER_BY_USER[FAILURE], { error }),
}

export const FETCH_PARENT_TEACHER_EVENT_BY_USER = createRequestTypes('FETCH_PARENT_TEACHER_EVENT_BY_USER')
export const fetchParentTeacherEvent = {
    request: query => action(FETCH_PARENT_TEACHER_EVENT_BY_USER[REQUEST], { query }),
    success: payload => action(FETCH_PARENT_TEACHER_EVENT_BY_USER[SUCCESS], { payload }),
    failure: error => action(FETCH_PARENT_TEACHER_EVENT_BY_USER[FAILURE], { error }),
}

export const SET_EVENT_RESPONSE = createRequestTypes('SET_EVENT_RESPONSE')
export const setEventResponse = {
    request: query => action(SET_EVENT_RESPONSE[REQUEST], { query }),
    success: payload => action(SET_EVENT_RESPONSE[SUCCESS], { payload }),
    failure: error => action(SET_EVENT_RESPONSE[FAILURE], { error }),
}

export const TRANSFER_OWNERSHIP = createRequestTypes('TRANSFER_OWNERSHIP')
export const transferResponse = {
    request: query => action(TRANSFER_OWNERSHIP[REQUEST], { query }),
    success: payload => action(TRANSFER_OWNERSHIP[SUCCESS], { payload }),
    failure: error => action(TRANSFER_OWNERSHIP[FAILURE], { error }),
}

export const DELETE_SLOT_TIME = createRequestTypes('DELETE_SLOT_TIME')
export const deleteTimeSlot = {
    request: query => action(DELETE_SLOT_TIME[REQUEST], { query }),
    success: payload => action(DELETE_SLOT_TIME[SUCCESS], { payload }),
    failure: error => action(DELETE_SLOT_TIME[FAILURE], { error }),
}

export const REMOVE_CHILD_EVENT = createRequestTypes('DELETE_CHILD_EVENT')
export const removeChildEvent = {
    request: query => action(REMOVE_CHILD_EVENT[REQUEST], { query }),
    success: payload => action(REMOVE_CHILD_EVENT[SUCCESS], { payload }),
    failure: error => action(REMOVE_CHILD_EVENT[FAILURE], { error }),
}