import {
    OPEN_DIALOG_BOX,
    CLOSE_DIALOG_BOX,
    SHOW_TOAST_MESSAGE,
    HIDE_TOAST_MESSAGE,
    PAGE_LOADER_START,
    PAGE_LOADER_FINISH
} from "../actions/component";


const INITIAL_STATE = {
    dialog: {
        open: false,
        typeOfDialog: ''
    },
    toast: {
        open: false,
        message: '',
        type: 'success',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }

    },
    pageLoaderCount: 0
}

const component = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_DIALOG_BOX:
            return {
                ...state,
                dialog: {
                    open: true,
                    typeOfDialog: action.payload
                }
            }
        case CLOSE_DIALOG_BOX:
            return {
                ...state,
                dialog: INITIAL_STATE.dialog
            }
        case SHOW_TOAST_MESSAGE:
            const anchorOrigin = action.payload.anchorOrigin || INITIAL_STATE.toast.anchorOrigin
            return {
                ...state,
                toast: {
                    open: true,
                    message: action.payload.message,
                    type: action.payload.type,
                    anchorOrigin
                }
            }
        case HIDE_TOAST_MESSAGE:
            return {
                ...state,
                toast: INITIAL_STATE.toast
            }
        case PAGE_LOADER_START:
            return {
                ...state,
               // pageLoaderCount: state.pageLoaderCount + 1
            };
        case PAGE_LOADER_FINISH:
            return {
                ...state,
                pageLoaderCount: state.pageLoaderCount - 1
            };
        default:
            return state
    }
}

export default component;