export const FETCH_CHILD_LIST_REQ = 'FETCH_CHILD_LIST_REQ';
export const FETCH_CHILD_LIST_SUCCESS = 'FETCH_CHILD_LIST_SUCCESS';
export const FETCH_CHILD_LIST_FAILURE = 'FETCH_CHILD_LIST_FAILURE';

export const fetchChildListReq = data =>({
    type:FETCH_CHILD_LIST_FAILURE,
    data,
})

export const fetchChildListSuccess = data =>({
    type:FETCH_CHILD_LIST_SUCCESS,
    data,
})
export const fetchChildListFailure = error =>({
    type:FETCH_CHILD_LIST_FAILURE,
    error,
})