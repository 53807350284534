import {
    action,
    createRequestTypes,
    REQUEST,
    SUCCESS,
    FAILURE
} from './common';

export const EDIT_POST_CONTENT = 'EDIT_POST_CONTENT';
export const handleEditPostContent = payload => action(EDIT_POST_CONTENT, {  payload});

export const FETCH_STAFF = createRequestTypes('FETCH_STAFF');
export const fetchStaff = {
    request: query => action(FETCH_STAFF[REQUEST], { query}),
    success: payload => action(FETCH_STAFF[SUCCESS], { payload}),
    failure: error => action(FETCH_STAFF[FAILURE], { error}),
}

export const FETCH_STUDENTS = createRequestTypes('FETCH_STUDENTS');
export const fetchStudents = {
    request: query => action(FETCH_STUDENTS[REQUEST], { query }),
    success: payload => action(FETCH_STUDENTS[SUCCESS], { payload}),
    failure: error => action(FETCH_STUDENTS[FAILURE], { error}),
}
