import React, { Component } from 'react';
import classNames from 'classnames';
import { Popconfirm, message } from 'antd'
import { connect } from 'react-redux';
import {
    withStyles, Grid, Card, Avatar,
    Typography, Button, Input, Select, MenuItem
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import CustomLoadingOverlay from "../component/common/customLoadingOverlay.jsx";

import parentService from '../service/parent';
import { fetchParentListSuccess } from '../redux/actions/parent';
import authService from '../service/auth';
import * as routes from '../routes/path';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    row: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(1) * 2,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1) * 2,
    },
    select: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },

});

class Parents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: 'all',
            columnDefs: this.createColumnDefs(),
            frameworkComponents: {
                customLoadingOverlay: CustomLoadingOverlay,
            },
            loadingOverlayComponent: "customLoadingOverlay",
            loadingOverlayComponentParams: { loadingMessage: "Loading Parent.... Please wait.." },
            onGridReady: this.onGridReady,
            rowData: [],
            style: {
                width: '100%',
                height: '100%'
            }
        }
    }

    componentDidMount() {
        this.fetchParents();
    }

    createColumnDefs() {
        return [
            {
                headerName: "Profile",
                field: "pictureurl",
                autoHeight: true,
                cellRendererFramework: this.profilePictureRenderer,
                width: 80
            },
            { headerName: "Email", field: "email", width: 100 },
            { headerName: "Given Name", field: "given_name", width: 100, autoHeight: true },
            { headerName: "family Name", field: "family_name", width: 100, autoHeight: true },
            { headerName: "Phone number", field: "phone_number", width: 100, autoHeight: true },
            { headerName: "Last Login", field: "lastlogin", width: 100, autoHeight: true },
            {
                headerName: "Action",
                field: "childid",
                width: 200,
                cellRendererFramework: this.actionColumnRenderer,
                autoHeight: true
            },
        ]
    }

    profilePictureRenderer = event => {
        const { classes } = this.props;
        if ((event.data.pictureurl === "<DEFAULT>") || (event.data.pictureurl === undefined) || (event.data.pictureurl === "") || (event.data.pictureurl === null)) {
            return (<Avatar className={classes.avatar}>{event.data.username[0].toUpperCase()}</Avatar>)
        } else {
            return (<Avatar className={classes.avatar} src={event.data.pictureurl}></Avatar>)
        }
    }

    actionColumnRenderer = event => {
        var invitationLink = () => {
            if (event.data.signedup === 'Y')
                return null
            else{
                var title = "Are you sure you want to send email invitation to " + event.data.email + " ?"
                if (event.data.sentinvitation === 'Y')
                {
                   return (<Popconfirm title={title} onConfirm={() => this.sendInvitationLink(event)} okText="Send" cancelText="No">
                        <button type="button" className="btn btn-link">Resend Invite</button>
                    </Popconfirm>)
                }else{
                    return (<Popconfirm title={title} onConfirm={() => this.sendInvitationLink(event)} okText="Send" cancelText="No">
                        <button type="button" className="btn btn-link">Send Invite</button>
                    </Popconfirm>)
                }
            }
        }

        return (
            <div>
            <div>
                <button type="button" className="btn btn-link" onClick={(e) => this.onEditParent(event)}>Edit</button>
                <Popconfirm title="Are you sure delete this child?" onConfirm={() => this.deleteChild(event)} okText="Yes" cancelText="No">
                    <button type="button" className="btn btn-link">Delete</button>
                </Popconfirm>

                <Popconfirm title="Please confirm you would like to send a reset password to the user?" onConfirm={() => this.resetPassword(event)} okText="Yes" cancelText="No">
                    <button type="button" className="btn btn-link">Reset Password</button>
                </Popconfirm>
            </div>
                <div>
                    {invitationLink()}
                </div>
            </div>
        )
    }

    sendInvitationLink(event){
        var user = event.data
        var body = {user : user.email, signupcode : user.signupcode}
        parentService.sendInvitation(body).then((res)=> {
            console.log(res)
            if (res.status === "success"){
                parentService.sendInvitationMark(body).then((res)=> {
                    if (res.status === "success") {
                        message.success('Invitation Email sent to ' + user.email)
                        this.fetchParents()

                    }else{
                        message.error('Error Marking Invitation as Sent ! ')
                    }
                })
            }else{
                message.error('Error Sending Invitation Link ! ')
            }

        })
    }

    fetchParents = async () => {
        try {
            this.startLoading();
            const { dispatch } = this.props;
            const { data } = await parentService.lastLogin();
            const { users } = await parentService.getParentList('all');
            const allUsers = users
                .filter(user => user['custom:role'] === 'parent')
                .map(user => {
                    if (data && data.length) {
                        const lastLoginUser = data.find(loginUser => loginUser.userid === user.username);
                        if (lastLoginUser) {
                            user.lastlogin = lastLoginUser.lastlogin
                        }
                    }
                    return user;
                });
            this.setState({ rowData: allUsers });
            dispatch(fetchParentListSuccess(allUsers))
            setTimeout(() => {
                this.gridApi.resetRowHeights();
            }, 100);
            this.stopLoading();
        } catch (error) {
            message.error('something not right!');
            this.stopLoading();
        }
    }

    deleteChild = event => {
        const { username } = event.data;
        if (!username) return;
        const body = {
            username,
        }
        parentService.deleteParent(body)
            .then(parentService.deleteParentDB(body).then(
                res => message.success('user Deleted')
            ))
            .then(() => this.fetchParents())
            .catch(err => console.log(err));
    }

    resetPassword = event => {
        const { username } = event.data;
        if (!username) return;
        authService.resetPassword(username).then(res => {
            message.success('Password reset')
        })
    }

    onClickAddStudent = event => {
        this.props.history.push(routes.PARENTS_ADD)
    }

    search = ev => {
        const value = ev.target.value ? ev.target.value.toString().trim() : '';
        this.gridApi.setQuickFilter(value);
    }

    onEditParent = (event) => {
        const { data } = event;
        const { username } = data
        this.props.history.push(routes.PARENTS_EDIT, { username })
    };

    handleSelectChange = (event) => {
        const { name, value } = event.target;
        const { parent: { parentList } } = this.props;
        this.setState({ [name]: value });
        if (value === 'all') {
            this.setState({ rowData: parentList });
        } else {
            const data = parentList && parentList.filter(child => child.groups.some(group => group === value));
            this.setState({ rowData: data });
        }
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();

        this.startLoading();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }

    startLoading = () => {
        if (this.gridApi)
            this.gridApi.showLoadingOverlay();
    }

    stopLoading = () => {
        this.gridApi.hideOverlay();
    }

    excelExport() {
        var params = {}
        this.gridApi.exportDataAsCsv(params);
    }

    render() {
        const { classes, currentUser: { userInfo } } = this.props;
        const { style } = this.state;

        return (
            <div className={classes.root} style={{ height: '100%' }}>
                <Grid container spacing={40} >
                    <Grid item xs={12}>
                        <Card className={classNames(classes.paper)}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography className={classes.typography} variant="h6" gutterBottom>Parents Dashboard</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        className={classes.select}
                                        value={this.state.category}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="category" id="category-helper" />}
                                    >
                                        <MenuItem value='all'>All Groups</MenuItem>
                                        {userInfo.Groups && Object.keys(userInfo.Groups).map((key, index) => (
                                            <MenuItem value={userInfo.Groups[key].groupid} key={index}>{userInfo.Groups[key].GroupName}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={7} >
                                    <Input placeholder="Search" onChange={this.search} className={classes.input} inputProps={{ 'aria-label': 'Description', }} />
                                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.onClickAddStudent}>
                                        Add Parents
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card >
                            <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.excelExport.bind(this)}>Download</Button>
                            <div className="ag-theme-balham" style={style}>
                                <AgGridReact
                                    ref={i=> this.gridRef = i}
                                    frameworkComponents={this.state.frameworkComponents}
                                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                                    loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                    animateRows={true}
                                    enableFilter={true}
                                    enableColResize={true}
                                    gridAutoHeight={true}
                                    onColumnResized={this.onColumnResized.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onGridReady={this.state.onGridReady.bind(this)} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>

            </div >
        );
    }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(withStyles(styles)(Parents));