import React from "react";
import "react-day-picker/lib/style.css";

import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Field,change } from "redux-form";

import RenderRadioGroup from "../../common/reduxForm/RenderRadioGroup";
import CheckBoxGroup from "./CheckboxGroup";
import store from '../../../redux/store';

const useStyles = makeStyles(theme => ({
  dateTimeCard: {
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "12px",
    padding: '10px'
  },
  tabroot: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold"
  },
  tabs: {
    marginTop: "-30px"
  },
  recurringTitle:{
    fontWeight:"bold",
    textAlign:"left",
    padding:20
  },
  recurring:{
    fontSize:14,
    textAlign:"left",
    paddingLeft:20,
  },
  recurringLabel:{
    fontSize:14,
    textAlign:"left",
  },
  daysStyle:{
    padding:10,
    background:"#f2f2f2"
  }
}));

const options = [
  { label: "MO", value: '1', },
  { label: "TU", value: '2', },
  { label: "WE", value: '3', },
  { label: "TH", value: '4', },
  { label: "FR", value: '5', },
  { label: "SA", value: '6', },
  { label: "SU", value: '7', }
];


export default function DateRangeRecurring(props) {
  const classes = useStyles();
  const { generalFormValues: { daterangetype,dates } } = props;

  function handleChange(event){
    store.dispatch(change('generalDetailsForm', `volunteer`, []));
    store.dispatch(change('generalDetailsForm', `dates`, {}));
  }

  function handleCheckBoxsChange(event,newValue, previousValue=[]){
    if(newValue.length<previousValue.length){
      let newDates = {};
      newValue.map(day => {
        if(dates[day]) {
          newDates = { ...newDates, [day]: dates[day] };
        };
        return day
      });
      store.dispatch(change('generalDetailsForm', `dates`, newDates));
    }
  }

  return (
    <>
      <Card className={classes.dateTimeCard}>
        <Typography variant="h5" component="h5" className={classes.recurringTitle}>
            Recurring
        </Typography>
        <Field name="daterangetype" component={RenderRadioGroup} onChange={handleChange}>
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Repeat every week on the days"
            classes={{
              root: classes.recurring,
              label: classes.recurringLabel,
            }}
          />
        </Field>

        {daterangetype === '1' &&
          (<Field name="selectedDays" className={classes.daysStyle} component={CheckBoxGroup} options={options} onChange={handleCheckBoxsChange} />)
        }

        <Field name="daterangetype" component={RenderRadioGroup}  onChange={handleChange}>
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Repeat every month on the same numeric 
            day as the start day (i.e. The 12th of every 
            month)"
            classes={{
              root: classes.recurring,
              label: classes.recurringLabel,
            }}
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label="Repeat every month on the same relative 
            day as the start day (i.e. The first Sunday 
            of every month)"
            classes={{
              root: classes.recurring,
              label: classes.recurringLabel,
            }}
          />

        </Field>

      </Card>
    </>
  );
}
