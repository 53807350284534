export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_CLASS_ID = 'SET_CLASS_ID';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_CLASS_LIST = 'SET_CLASS_LIST';
export const FETCH_ATTENDANCE_DATA = 'FETCH_ATTENDANCE_DATA';


export const setDateRange = data => ({
    type: SET_DATE_RANGE,
    data,
})

export const setClassId = data => ({
    type: SET_CLASS_ID,
    data,
})

export const setSearchText = data => ({
    type: SET_SEARCH_TEXT,
    data,
})
export const setClassList = data => ({
    type: SET_CLASS_LIST,
    data,
})

export const fetchAttendanceData = data => ({
    type: FETCH_ATTENDANCE_DATA,
    data,
})