import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import { reduxForm, Form, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import RenderMeetingCalendar from "./RenderMeetingCalendar";
import { setInitialValue } from '../../../redux/actions/event';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  datePickerSection: {
    margin: "0 auto"
  },
  card: {
    display: "flex",
    minHeight: "300px"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    height: 400,
    margin: "-50px 0px",
  },
  coverContent: {
    padding: 25
  },
  rsvpIcon: {
    padding: "20px 0px 0px 15px",
    height: 70,
  },
  rsvpDateTime: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    textAlign: "right"
  },
  rsvpDateTimeChild: {
    flex: "0 0 calc(70% - 10px)"
  },
  rsvpDateTimeChildimg: {
    flex: "0 0 calc(30% - 10px)"
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
});

class MeetingDateTime extends Component {

  state = {
    image: "https://picsum.photos/200/300/?blur",
    value: 0,
    event_id: '',
    dates: []
  }

  handlePreviousStep = () => {
    const {
      setStepperActiveStep,
      previousStep
    } = this.props;
    setStepperActiveStep(previousStep);
  };

  handleNextStep = async () => {
    const {
      generalDetailsubmitForm,
      setStepperActiveStep,
      nextStep
    } = this.props;
    await generalDetailsubmitForm();
    setStepperActiveStep(nextStep);
  }

  onFormSubmit = data => {
    this.props.setInitialValue(data);
    this.handleNextStep();
  }

  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div className={classes.root} >
        <Form onSubmit={handleSubmit(this.onFormSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.control}>
              <Grid container>
                <Grid item xs={10} className="centerdiv">
                  <FieldArray name="parentTeacherResponse" component={RenderMeetingCalendar} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.actionButton}>
            <Button variant="contained" onClick={this.handlePreviousStep}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            // disabled={!isValidForm}
            >
              Next
            </Button>
          </div>
        </Form>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.event.formInitialValue
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setInitialValue: (payload) => dispatch(setInitialValue(payload)),
  }
};

const meetingDateTime = reduxForm({
  form: "generalDetailsForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(MeetingDateTime);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(meetingDateTime));
