import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { MangePostSelector } from "../redux/selector";
import {  editPost } from "../redux/actions/feed-post";
import { fetchStaff, fetchStudents } from '../redux/actions/post-manage';
import UpdatePost from '../component/UpdatePost';
import {  closeDialogBox } from '../redux/actions/component';

const selector = formValueSelector('editEvent')

const mapStateToProps = state => {
    const GroupOptions = MangePostSelector.convertGroupOptions(state)
    return {
        GroupOptions,
        staffList:state.postManage.staffList,
        studentsList: state.postManage.studentsList,
        FormValues: selector(state, 'posttime', 'posttype','groupids'),
        currentUser: state.currentUser,
        dialogProps: state.component.dialog,
    }
}

const mapDispatchToProps = dispatch => ({
    editPostAction: bindActionCreators(editPost, dispatch),
    fetchStaffAction: bindActionCreators(fetchStaff, dispatch),
    fetchStudentsAction: bindActionCreators(fetchStudents, dispatch),
    closeDialogAction: bindActionCreators(closeDialogBox, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePost);
