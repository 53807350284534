import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import DateTimeSlotCard from "./DateTimeSlotCard";
import Card from "@material-ui/core/Card";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    dateTimeCard: {
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
}));

export default function RenderMeetingCalendar(props) {

    const classes = useStyles();
    const { fields } = props;
    const initialDates = Array.isArray(fields.getAll()) ? fields.getAll().map(field => field.event_date) : [];
    const INITIAL_STATE = { selectedDays: initialDates }
    const [state, setState] = React.useState(INITIAL_STATE)

    function handleDayClick(day, { selected, disabled }) {
        if (disabled) {
            return
        }
        const { selectedDays } = state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay => DateUtils.isSameDay(selectedDay, day));
            selectedDays.splice(selectedIndex, 1);
            fields.remove(selectedIndex)
        } else {
            selectedDays.push(day);
            fields.push({ event_date: day })
        }
        setState({ selectedDays });
    }

    return (
        <>
            <Card className={classes.dateTimeCard}>
                <DayPicker
                    numberOfMonths={2}
                    selectedDays={state.selectedDays}
                    onDayClick={handleDayClick}
                    disabledDays={{ before: new Date() }}
                />
            </Card>
            <DateTimeSlotCard fields={fields} />
        </>
    );

}