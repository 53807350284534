import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  reduxForm, Form, FieldArray,
  Field, change, formValueSelector
} from 'redux-form';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import CalendarMultipleDate from "./CalendarMultipleDate";
import CalendarSingleDate from "./CalendarSingleDate";
import CalendarNoDate from "./CalendarNoDate";
import CalenderDateRange from "./CalenderDateRange"
import { setInitialValue } from '../../../redux/actions/event';
import RenderTabs from '../../common/reduxForm/RenderTabs';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "-80px"
  },
  datePickerSection: {
    margin: "0 auto"
  },
  tabroot: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  tabs: {
    padding: "15px 0px"
  },
  card: {
    display: "flex",
    minHeight: "300px"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
});

class VolunteerDateTime extends Component {

  state = {
    image: "https://picsum.photos/200/300/?blur",
    value: 0,
    event_id: '',
    dates: []
  }

  handlePreviousStep = () => {
    const {
      setStepperActiveStep,
      previousStep
    } = this.props;
    setStepperActiveStep(previousStep);
  };

  handleNextStep = async () => {
    const {
      generalDetailsubmitForm,
      setStepperActiveStep,
      nextStep
    } = this.props;
    await generalDetailsubmitForm();
    setStepperActiveStep(nextStep);
  }

  onFormSubmit = data => {
    const values = this.transFormData(data)
    this.props.setInitialValue(values);
    this.handleNextStep();
  }

  transFormData(values) {
    const data = { ...values };
    const { dates, slotTime, volunteer } = data;

    if (data.volunteertype === '3') {
      const newVolunteer = [];
      for (const day in dates) {
        if (dates.hasOwnProperty(day)) {
          const element = dates[day];
          const result = Array.isArray(element) &&
            element.map(date => ({  event_date: date, slotTime }));
          newVolunteer.push(...result);
        }
      }

      if (!volunteer.length) {
        data.volunteer = newVolunteer
      } else {
        const newVolunteerWithSpots = newVolunteer.map(value => {
          const date = volunteer.find(volunteer => volunteer.event_date.toString() === value.event_date.toString());
          if (date) {
            const { slotTime: volunteertimeSlots } = date;
            const { slotTime: currentTimeSlots } = value;
            const hasSpots = volunteertimeSlots.some(timeSlot => timeSlot.spot);
            if (hasSpots) {
              const timeSlotWithSpots = currentTimeSlots.map(currentTimeSlot => {
                const volunteertimeSlot = volunteertimeSlots.find(slot => slot.start_time.toString() === currentTimeSlot.start_time.toString() && slot.end_time.toString() === currentTimeSlot.end_time.toString());
                if (volunteertimeSlot) {
                  return volunteertimeSlot;
                }
                return currentTimeSlot;
              });
              return { ...value, slotTime: timeSlotWithSpots };
            }
          }
          return value;
        });
        data.volunteer = newVolunteerWithSpots
      }
    }
    return data;
  }

  handleTabeChange = (event) => {
    this.props.changeFieldValue('volunteer', [])
  }

  render() {
    const { classes, handleSubmit,
      generalFormValues: { volunteertype }
    } = this.props;

    return (
      <div className={classes.root} >
        <Form onSubmit={handleSubmit(this.onFormSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.control}>
              <Grid container >
                <Grid item xs={12} className="centerdiv">
                  <Field
                    name="volunteertype"
                    component={RenderTabs}
                    className={classes.tabs}
                    tabClassName={classes.tabroot}
                    onChange={this.handleTabeChange}
                  />

                  {volunteertype === '1' && (<FieldArray name="volunteer" component={CalendarSingleDate} />)}
                  {volunteertype === '2' && (<FieldArray name="volunteer" component={CalendarMultipleDate} />)}
                  {volunteertype === '3' &&
                    (
                      <Field
                        name="daterange"
                        component={CalenderDateRange}
                        generalFormValues={this.props.generalFormValues}
                      />)
                  }
                  {volunteertype === '4' && (<FieldArray name="volunteer" component={CalendarNoDate} volunteertype={volunteertype} />)}

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.actionButton}>
            <Button variant="contained" onClick={this.handlePreviousStep}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            // disabled={!isValidForm}
            >
              Next
            </Button>
          </div>
        </Form>
      </div >
    );
  }
}

const selector = formValueSelector('generalDetailsForm');

const mapStateToProps = state => {
  return {
    initialValues: state.event.formInitialValue,
    generalFormValues: selector(state, 'volunteertype', 'daterange', 'daterangetype', 'selectedDays', 'dates'),
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setInitialValue: (payload) => dispatch(setInitialValue(payload)),
    changeFieldValue: (field, value) => dispatch(change("generalDetailsForm", field, value))
  }
};

const volunteerDateTime = reduxForm({
  form: "generalDetailsForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

})(VolunteerDateTime);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(volunteerDateTime));
