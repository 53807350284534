import { FETCH_BULK_USERS_LIST_SUCCESS } from "../actions/users";


const INITIAL_STATE = {
    userList: 'Bulk Testing1534673265027.xlsx',
    error: null,
    isLoading: false,
}


const cache = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BULK_USERS_LIST_SUCCESS:
            return {
                ...state,
                userList: action.data,
            }
        
        default:
            return state
    }
}

export default cache;