import React from 'react';
import PropTypes from 'prop-types';
import MartialDialog from '@material-ui/core/Dialog';

function withDialog(WrappedComponent) {

    function Dialog(props) {
        const { maxWidth, fullWidth,fullScreen, scroll, open, onClose, ...other } = props
        return (
            <MartialDialog
                maxWidth={maxWidth}
                aria-labelledby="confirmation-dialog-title"
                fullWidth={fullWidth}
                scroll={scroll}
                open={open}
                onClose={onClose}
                fullScreen={fullScreen}
            >
                <WrappedComponent {...other} />

            </MartialDialog>
        );
    }

    Dialog.propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        value: PropTypes.string,
        maxWidth: PropTypes.string,
        fullWidth: PropTypes.bool,
        scroll: PropTypes.string
    };

    Dialog.defaultProps = {
        maxWidth: "md",
        scroll: "body",
        fullWidth: true,
        fullScreen:false
    }

    return Dialog;
}

export default withDialog