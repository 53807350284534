
import {
    SUCCESS,
    REQUEST,
} from "../actions/common";
import { NEWSLETTER_STEPPER_STEP } from "../actions/newsletter";

const INITIAL_STATE = {
    activeStep: 0
}

const newsletter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEWSLETTER_STEPPER_STEP:
            return {
                ...state,
                activeStep: action.payload
            }

        default:
            return state
    }
}

export default newsletter;