import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Moment from "react-moment";
import { FieldArray } from 'redux-form';
import TimeSlots from "./TimeSlots";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    display: "flex",
    minHeight: "300px"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    height: 400,
    margin: "-50px 0px",
  },
  coverContent: {
    padding: 25
  },
  rsvpIcon: {
    padding: "20px 0px 0px 15px",
    height: 70,
  },
  rsvpDateTime: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    textAlign: "right"
  },
  rsvpDateTimeChild: {
    flex: "0 0 calc(70% - 10px)"
  },
  rsvpDateTimeChildimg: {
    flex: "0 0 calc(30% - 10px)"
  },
  dateTimeCard: {
    padding: 20,
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "12px"
  },
  dateTitme: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#05b9a4",
  },
  slotTime: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000",
    border: "1px solid rgba(143, 143, 150, 0.38)",
    borderRadius: "50px",
    padding: "0px 10px",
    textAlign: "center"
  },
  breakTag: {
    textAlign: "center",
    marginTop: "10px"
  },
  control: {
    margin: "25px 0px"
  },
  addTime: {
    border: "1px solid #05b9a4",
    backgroundColor: "#05b9a4",
    color: "#fff",
    borderRadius: "50px",
    position: "absolute",
    left: "49%",
    "&:hover": {
      backgroundColor: "#05b9a4",
    },
    dataSlotSection:{
      marginBottom: 15,
    }
  }
});

class DateTimeSlotCard extends Component {

  render() {
    const { classes, fields,selectedDate } = this.props;
    return (
      <>
        {fields.map((date, index) => (
          <div className={classes.root} key={index} >
            <Grid container>
              <Grid item xs={12} className={classes.control}>
                <Grid container spacing={10}>
                  <Grid item xs={12} className="centerdatetime">
                    <Card className={classes.dateTimeCard}>
                      <Grid container>
                        <Grid item xs={4} className={classes.dateTitme}>
                          <Moment format="ddd D, MMMM YYYY">
                            {selectedDate[index]}
                          </Moment>
                        </Grid>
                      </Grid>
                      <FieldArray name={`${date}.slotTime`} component={TimeSlots} />
                     
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ))}
      </>
    );
  }
}


export default withStyles(styles)(DateTimeSlotCard)
