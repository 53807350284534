import {
    REQUEST,
    SUCCESS,
    FAILURE,
    createRequestTypes,
    action
} from './common';

export const FETCH_USER_CACHE = createRequestTypes('FETCH_USER_CACHE');
export const fetchUserCatch = {

    request: query => action(FETCH_USER_CACHE[REQUEST], {query}),
    success: payload => action(FETCH_USER_CACHE[SUCCESS], {payload}),
    failure: error => action(FETCH_USER_CACHE[FAILURE], {error})
}
