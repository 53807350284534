import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from 'moment'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {
  FieldArray, FormSection,
} from 'redux-form';

import "react-day-picker/lib/style.css";
import "./style.css"

import DateTimeSlotCard from "./DateTimeSlotCard.daterange";
import DateRangeRecurring from "./DateRangeRecurring";
import RenderDayEveryWeek from "./RenderDay.everyweek";
import RenderDayNumericDay from "./RenderDay.numericday";
import RenderDayRelativeDay from "./RenderDay.relativeday";



class CalenderDateRange extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  componentDidMount() {
    const { input } = this.props;
    if (input.value) {
      this.setState({ ...input.value })
    }
  }

  handleDayClick = (day,{ disabled }) => {
    if (disabled) {
      return
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    this.props.input.onChange(range)
  };

  render() {
    const { from, to } = this.state;
    const { classes } = this.props;
    const {
      generalFormValues: { selectedDays, daterangetype }
    } = this.props

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <DateRangeRecurring
                generalFormValues={this.props.generalFormValues}
              />
            </Grid>
            <Grid item xs={8}>
              <Card>
                <DayPicker
                  numberOfMonths={2}
                  className="Selectable"
                  selectedDays={[from, { from, to }]}
                  onDayClick={this.handleDayClick}
                  disabledDays={{ before: new Date() }}
                />

              </Card>
              {from && to && (
                <>
                  <DateTimeSlotCard selectedDate={this.state} />

                  <FormSection name="dates">
                    {daterangetype === '1' && Array.isArray(selectedDays) && selectedDays.map(day => (
                      <FieldArray name={day} key={day} component={RenderDayEveryWeek} range={this.state} />
                    ))}

                    {daterangetype === '2' && (
                      <FieldArray name="1" component={RenderDayNumericDay} range={this.state} />
                    )}

                    {daterangetype === '3' && (
                      <FieldArray name="1" component={RenderDayRelativeDay} range={this.state} />
                    )}

                  </FormSection>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>



    );
  }
}

export default CalenderDateRange

