import { API } from 'aws-amplify';


const getChildOfUser = (data) => {
    const init = {
        body: {
            group: data
        }
    }
    return API.get('get_child_of_user', '', init).then(res => res);
}

const getStudentList = (data) => {
    const init = {
        body: {
            group: data
        }
    }
    return API.post('get_students', '', init).then(res => res);
}

const addStudent = (body) => {
    const init = {
        body
    }
    return API.post('add_students', '', init).then(res => res);
}
const editStudent = (body) => {
    const init = {
        body
    }
    return API.post('edit_students', '', init).then(res => res);
}

const getChildById = (body) => {
    const init = {
        body
    }
    return API.post('get_child_by_id', '', init).then(res => res);
}

const getChildrenForGroups = (body) => {
    const init = {
        body
    }
    return API.post('getchildrenforgroups', '', init).then(res => res);
}

const getChildrenForTeacherGroups = (body) => {
    const init = {
        body
    }
    return API.post('getChildrenForTeacherGroups', '', init).then(res => res);
}

const studentService = { 
    getChildOfUser, getStudentList, addStudent, 
    editStudent, getChildById, getChildrenForGroups,
    getChildrenForTeacherGroups
}

export default studentService;