import {  FETCH_USER_CACHE } from "../actions/cache";
import { SUCCESS } from "../actions/common";


const INITIAL_STATE = {
    userCache:{},
    groupsCache:{},
    error: null,
    isLoading: false,
}


const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {        
        case FETCH_USER_CACHE[SUCCESS]:
        return{
            ...state,
            userCache:action.payload.users,
            groupsCache:action.payload.groups
        }    

        default:
            return state
    }
}

export default user;