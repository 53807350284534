import { FETCH_PARENT_LIST_SUCCESS, FETCH_PARENT_LIST_FAILURE } from "../actions/parent";


const INITIAL_STATE = {
    parentList: [],
    error: null,
    isLoading: false,
}

const parent = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PARENT_LIST_SUCCESS:
            return {
                ...state,
                parentList: action.data,
            }
        case FETCH_PARENT_LIST_FAILURE:
            return state
        default:
            return state
    }
}

export default parent;