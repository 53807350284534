import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const RenderSwitch = ({
    label,
    className,
    meta: { touched, error } = {},
    input,
    color,
    isDisabled = false,
    ...props
}) => (
        <FormControlLabel
            control={
                <Switch
                    color={color}
                    checked={Boolean(input.value)}
                    onChange={input.onChange}
                    disabled={isDisabled}
                    {...input} 
                />
            }
            label={label} />
    );

export default RenderSwitch;