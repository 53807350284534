import { FETCH_CHILD_LIST_SUCCESS, FETCH_CHILD_LIST_FAILURE } from "../actions/child";


const INITIAL_STATE = {
    childList: [],
    error: null,
    isLoading: false,
}

const child = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CHILD_LIST_SUCCESS:
            return {
                ...state,
                childList: action.data,
            }
        case FETCH_CHILD_LIST_FAILURE:
            return state
        default:
            return state
    }
}

export default child;