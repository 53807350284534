import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import {
    FieldArray,    
} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import TimeSlotsWithSpot from './TimeSlotsWithSpot';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';



const styles = theme => ({
    dateTimeCard: {
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px",
        marginBottom:"10px"
    },
    slotReminderTitle: {
        padding: "20px 0 0 35px",
        textAlign: "left",
        color: "#05b9a4",
        fontWeight: "bold"
    },
    slot: {
        color: "#3c3989",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    slotContainer: {
        backgroundColor: "#f8f8f8"
    },
    itemDetails: {
        color: "#000000",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    }
});

class DatesCard extends Component {

    static propTypes = {

    }

    render() {
        const { classes, fields } = this.props;

        return (
            <>
                {fields.map((date, index) => (
                    <Card key={index} className={classes.dateTimeCard}>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" className={classes.slotReminderTitle}>
                                    <Moment format="DD MMM YYYY">
                                        {fields.get(index).event_date}
                                    </Moment>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Table className={`${classes.table} MuiTable-root TimeSlotsWithSpot-table-707`} fixedheader="false">
                            <TableBody>
                                <TableRow >
                                    <TableCell align="left" style={{width: "40%"}}>

                                    </TableCell>
                                    <TableCell align="left" >
                                        <Grid item xs={5} >
                                            <Typography variant="h6">
                                                Items
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Grid item xs={5} >
                                            <Typography variant="h6">
                                                Comments
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <FieldArray name={`${date}.slotTime`} component={TimeSlotsWithSpot} />
                    </Card>

                ))}
            </>
        )
    }
}

export default withStyles(styles)(DatesCard)
