import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const renderCheckbox = ({
    label,
    className,
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => {
    return(
        <FormControlLabel
            control={<Checkbox
                color="primary"
                checked={inputProps.value ? true : false}
                onChange={inputProps.onChange}
            />}
            label={label} />
    )}

export default renderCheckbox;