import React ,{useState} from 'react';
import { TimePicker } from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';


function RenderTimePicker(props) {
  const {
    label,
    className,
    meta: {  error },
    input,
    ...custom
  } = props
  
  const initialValue = input.value||new Date();
  const [selectedDate, setSelectedDate] = useState(initialValue);

  function handleDateChange(value){
    setSelectedDate(value);
    input.onChange(value)
  }

  return (
    <>
      <TimePicker
        label={label}
        className={className}
        inputVariant='outlined'
        aria-describedby="component-error-text"
        value={selectedDate}
        onChange={handleDateChange}
        {...custom}
        // {...input}
        // error={error}
      />
      <FormHelperText id="component-error-text" className="errorText">{error}</FormHelperText>
    </>
  );
}

export default RenderTimePicker;