import React from "react";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";

function RenderTextField(props) {
    const {
        label,
        className,
        meta: { touched, error },
        input,
        ...custom
    } = props
    return (
        <>
            <TextField
                label={label}
                margin="normal"
                className={className}
                variant="outlined"
                aria-describedby="component-error-text"
                {...input}
                {...custom}
                // error={touched&&error}
            />
            <FormHelperText id="component-error-text" className="errorText">{touched&&error}</FormHelperText>
        </>
    );
}

export default RenderTextField