import React, { Component } from 'react'
import { Typography, Grid, Button, Icon } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({

    itemDetails: {
        color: "#000000",
        fontWeight: "bold",
        padding: "35px",
        textAlign: "left"
    },
    slot:{
        padding:"10px"
    }
});


class Spot extends Component {
    render() {
        const { classes, fields } = this.props;
        return (
            <>
                {fields.map((spot, index) => (
                    <Grid container key={index} spacing={0} className={classes.slot}>
                        <Grid item xs={6} >
                            <Typography variant="h6">
                                {`${fields.get(index).description}(${fields.get(index).person})`}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} >
                            <Typography variant="h6">
                                {`${fields.get(index).comment || ""}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className="deleteIcon">
                            <Button onClick={() => fields.remove(index)}>
                                <Icon>delete</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </>

        )
    }
}

export default withStyles(styles)(Spot)