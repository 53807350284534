import {
    REQUEST,
    SUCCESS,
    FAILURE,
    action,
    createRequestTypes
} from './common';

export const APPLY_POST_FILTER = 'APPLY_POST_FILTER';
export const applyPostFilter = payload => action(APPLY_POST_FILTER, {payload});


export const FETCH_POST = createRequestTypes('FETCH_POST');
export const fetchPost = {
    request: query => action(FETCH_POST[REQUEST], {query}),
    success: payload => action(FETCH_POST[SUCCESS], {payload}),
    failure: error => action(FETCH_POST[FAILURE], {error}),
}

export const POST_VIEW = createRequestTypes('POST_VIEW');
export const getPostView = {
    request: query => action(POST_VIEW[REQUEST], {query}),
    success: payload => action(POST_VIEW[SUCCESS], {payload}),
    failure: error => action(POST_VIEW[FAILURE], {error}),
}

export const FETCH_PINNED_POST = createRequestTypes('FETCH_PINNED_POST');

export const fetchPinnedPost = {
    request: query => action(FETCH_PINNED_POST[REQUEST], { query }),
    success: payload => action(FETCH_PINNED_POST[SUCCESS], { payload }),
    failure: error => action(FETCH_PINNED_POST[FAILURE], { error }),
}

export const CREATE_POST = createRequestTypes('CREATE_POST');
export const createPost = {
    request: query => action(CREATE_POST[REQUEST], {query}),
    success: payload => action(CREATE_POST[SUCCESS], {payload}),
    failure: error => action(CREATE_POST[FAILURE], {error}),
}

export const EDIT_POST = createRequestTypes('EDIT_POST');
export const editPost = {
    request: query => action(EDIT_POST[REQUEST], {query}),
    success: payload => action(EDIT_POST[SUCCESS], {payload}),
    failure: error => action(EDIT_POST[FAILURE], {error}),
}

export const DELETE_POST = createRequestTypes('DELETE_POST');
export const deletePost = {
    request: query => action(DELETE_POST[REQUEST], {query}),
    success: payload => action(DELETE_POST[SUCCESS], {payload}),
    failure: error => action(DELETE_POST[FAILURE], {error}),
}

export const SAVE_COMMENT = createRequestTypes('SAVE_COMMENT');
export const saveComment = {
    request: query => action(SAVE_COMMENT[REQUEST], {query}),
    success: payload => action(SAVE_COMMENT[SUCCESS], {payload}),
    failure: error => action(SAVE_COMMENT[FAILURE], {error}),
}

export const UPDATE_COMMENT = createRequestTypes('UPDATE_COMMENT');
export const updateComment = {
    request: query => action(UPDATE_COMMENT[REQUEST], {query}),
    success: payload => action(UPDATE_COMMENT[SUCCESS], {payload}),
    failure: error => action(UPDATE_COMMENT[FAILURE], {error}),
}

export const DELETE_COMMENT = createRequestTypes('DELETE_COMMENT');
export const deleteComment = {
    request: query => action(DELETE_COMMENT[REQUEST], {query}),
    success: payload => action(DELETE_COMMENT[SUCCESS], {payload}),
    failure: error => action(DELETE_COMMENT[FAILURE], {error}),
}

export const LIKE_POST = createRequestTypes('LIKE_POST');
export const likePost = {
    request: query => action(LIKE_POST[REQUEST], {query}),
    success: payload => action(LIKE_POST[SUCCESS], {payload}),
    failure: error => action(LIKE_POST[FAILURE], {error}),
}

export const UNLIKE_POST = createRequestTypes('UNLIKE_POST');
export const unlikePost = {
    request: query => action(UNLIKE_POST[REQUEST], {query}),
    success: payload => action(UNLIKE_POST[SUCCESS], {payload}),
    failure: error => action(UNLIKE_POST[FAILURE], {error}),
}