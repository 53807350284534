import React, { Component } from 'react'

export class ComingSoon extends Component {
  render() {
    return (
      <div>
        ComingSoon
      </div>
    )
  }
}

export default ComingSoon
